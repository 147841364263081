//3rd party
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//BL
import { app_user_register } from '../../../Pages/authentication/servicesAuthentication';
import { app_user_login } from '../../../BusinessLogic/redux/reducers/appUser';
import {
	signupFormInitialValues,
	artistSignupFormValidationSchema,
	gallerySignupFormValidationSchema,
} from '../../../BusinessLogic/data/Perfil';
import { getCountries } from '../../../BusinessLogic/redux/reducers/staticData';
import { resetRedirectRoute } from '../../../BusinessLogic/redux/reducers/routesHistory';

//UI
import FieldTextFormik from '../../molecules/Field/FieldTextFormik';
import FieldPasswordFormik from '../../molecules/Field/FieldPasswordFormik';
import ButtonFabExtendedPrimary from '../../molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import CheckBoxWithTextTermsOfUse from '../../molecules/Field/CheckBoxWithTextTermsOfUse';
import ListItemText from '../../atoms/Inputs/ListItemText';
import AuthenticationTemplate from '../../templates/AuthenticationTemplate';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import LoadingText from '../../../UI/molecules/Loading/LoadingText';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import ImageArtistSignUp from '../../assets/images/ImageArtistSignUp.jpg';
import ImageArtistSignUpCompressed from '../../assets/images/ImageArtistSignUpCompressed.jpg';
import ImageGallerySignUp from '../../assets/images/ImageGallerySignUp.jpg';
import ImageGallerySignUpCompressed from '../../assets/images/ImageGallerySignUpCompressed.jpg';
import { ModalPostulation } from '../PopUp/ModalPostulation';

/**
 * @description Second element in the sign up flow
 * @param {*} param0
 * @returns
 */

const SignupForm = () => {
	const { t } = useTranslation();
	const { user_type } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { countries } = useSelector((state) => state.staticData);
	const { redirectRoute } = useSelector((state) => state.routesHistory);

	const [policyAccept, setPolicyAccept] = useState(false);
	const [showError, setShowError] = useState(false);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState({
		email: '',
		password: '',
	});
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showPaymentsModal, setShowPaymentsModal] = useState(false);

	const isValidUserType =
		user_type === 'artista' ||
		user_type === 'galeria' ||
		user_type === 'organizacion';
	const errorMessageDesign = 'text-red-400 body-Text3';

	const switchTypeUSerTextModal = () => {
		if (user_type === 'artista') {
			return t('TuCuentaSeHaCreadoExitosamente');
		} else if (user_type === 'galeria') {
			return t('GaleríaTuCuentaSeHaCreadoExitosamente');
		} else if (user_type === 'organizacion') {
			return t('OrganizaciónTuCuentaSeHaCreadoExitosamente');
		}
	};

	const switchRoleCompanyTranslate = () => {
		if (user_type === 'artista') {
			return 'ARTIST';
		} else if (user_type === 'galeria') {
			return 'GALLERY';
		} else if (user_type === 'organizacion') {
			return 'ORGANIZATION';
		}
	};

	const registerUser = (values) => {
		const valuesForm = {
			...values,
			role_company: switchRoleCompanyTranslate(),
		};
		app_user_register(valuesForm)
			.then(() => {
				const { email, password } = valuesForm;
				setUserData({ email, password });
				setShowConfirmationModal(true);
				setLoading(false);
			})
			.catch((error) => {
				if (`${error}` === 'Error: User already exists') {
					alert('Este email ya fue registrado. Por favor utiliza otro email.');
					setLoading(false);
				}
			});
	};

	const loginUser = (loginUserData) => {
		setLoading(true);
		setShowConfirmationModal(false);
		dispatch(app_user_login(loginUserData)).then(() => {
			if (user_type === 'artista') {
				navigate('/');
			} else {
				navigate('/');
				setTimeout(() => {
					navigate(redirectRoute);
					dispatch(resetRedirectRoute());
				}, '100');
			}
			dispatch(resetRedirectRoute());
		});
	};

	const onConfirmationModalSubmit = () => {
		// if (user_type === 'artista') {
		// 	setShowConfirmationModal(false);
		// 	setShowPaymentsModal(true);
		// 	return;
		// }
		loginUser(userData);
	};

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
	}, [countries, dispatch]);

	useEffect(() => {
		if (!isValidUserType) {
			navigate('/');
		}
	}, [navigate, isValidUserType]);

	return (
		<AuthenticationTemplate
			image={user_type === 'artista' ? ImageArtistSignUp : ImageGallerySignUp}
			placeholderSrc={
				user_type === 'artista'
					? ImageArtistSignUpCompressed
					: ImageGallerySignUpCompressed
			}
		>
			{showConfirmationModal && (
				<ModalPostulation
					actionClose={onConfirmationModalSubmit}
					modalTitle={t('CuentaCreada')}
					modalText={switchTypeUSerTextModal()}
					buttonLabel={t('Aceptar')}
				/>
			)}
			{showPaymentsModal && (
				<ModalPostulation
					actionClose={() => loginUser(userData)}
					modalTitle={t('PlanesModalTitulo')}
					modalText={t('PlanesModalTexto')}
					buttonLabel={t('PlanesModalBoton')}
				/>
			)}
			<div className='flex flex-col gap-2 text-center'>
				<TextTitleSub textColor='text-green-50' title={t('CrearCuenta')} />
				<TextContentSecond design='text-green-50' text={t('IngresaDatos')} />
			</div>
			<div className='mx-auto w-4/5 sm:w-3/5 md:w-11/12'>
				<Formik
					initialValues={signupFormInitialValues}
					validationSchema={
						(user_type === 'artista' && artistSignupFormValidationSchema) ||
						(user_type === 'galeria' && gallerySignupFormValidationSchema)
					}
					onSubmit={(values) => {
						if (policyAccept) {
							setLoading(true);
							registerUser(values);
						}
					}}
				>
					{(formProps) => (
						<Form className='p-3'>
							<FieldTextFormik
								label={`${
									user_type === 'galeria' || user_type === 'organizacion'
										? t('Empresa')
										: t('Nombres')
								} *`}
								dbName='first_name'
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								errors={formProps.errors?.first_name}
							/>
							{user_type === 'artista' && (
								<FieldTextFormik
									label={`${t('Apellidos')} *`}
									dbName='last_name'
									errorMessageDesign={errorMessageDesign}
									textInlineStyle={{ fontWeight: 300 }}
									errors={formProps.errors?.last_name}
								/>
							)}
							<div className='space-between flex flex-row justify-between px-0 py-2 '>
								<ListItemText
									design='body-Text2 w-32 self-center'
									primary={t('País')}
								/>
								<div className='w-40 truncate rounded border-none md:w-56 '>
									<Field
										name='country_id'
										type='Text'
										as='select'
										className=' body-Text2 h-6 w-full bg-light_grey-75 '
									>
										{countries?.map((country, index) => (
											<option
												key={index}
												y={country.value}
												value={country.value}
											>
												{country.label}
											</option>
										))}
									</Field>
								</div>
							</div>
							<FieldTextFormik
								label={`${t('Email')} *`}
								dbName='email'
								type='email'
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								errors={formProps.errors?.email}
							/>
							<FieldPasswordFormik
								label={`${t('Contraseña')} *`}
								dbName='password'
								type={passwordVisibility ? 'text' : 'password'}
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								passwordVisibility={passwordVisibility}
								setPasswordVisibility={setPasswordVisibility}
								errors={formProps.errors?.password}
							/>

							<FieldPasswordFormik
								label={`${t('ConfirmarContraseña')} *`}
								dbName='confirm_password'
								type={confirmPasswordVisibility ? 'text' : 'password'}
								errorMessageDesign={errorMessageDesign}
								textInlineStyle={{ fontWeight: 300 }}
								labelDesign='w-24 sm:w-max md:w-24 xl:w-max'
								confirmPasswordVisibility={confirmPasswordVisibility}
								setConfirmPasswordVisibility={setConfirmPasswordVisibility}
								errors={formProps.errors?.confirm_password}
							/>
							<CheckBoxWithTextTermsOfUse
								setPolicyAccept={setPolicyAccept}
								policyAccept={policyAccept}
								design='flex flex-row mt-10'
							/>
							{!policyAccept && showError && (
								<div className={`pl-7 pt-1 ${errorMessageDesign}`}>
									{t('PolíticasSeguridadRequerido')}
								</div>
							)}
							<div className='mt-4 flex justify-center'>
								{loading ? (
									<LoadingText text={t('Guardando')} />
								) : (
									<ButtonFabExtendedPrimary
										text={t('Continuar')}
										action={() => {
											!policyAccept ? setShowError(true) : setShowError(false);
											// dispatch(updateOnboardingFirstLogged(true));
										}}
									/>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</AuthenticationTemplate>
	);
};

export default SignupForm;
