export const paymentsOptions = {
	artist: {
		monthly: [
			{
				name: 'PlanGratis',
				role: 'ArtistaIndividual',
				price: '0 US$',
				duration: 'PorSiempre',
				benefits: {
					common: [
						'AccesoALaWebApp',
						'InventarioDeObrasIlimitadas',
						'CreacionDePortafoliosIlimitados',
						'AccesoAConvocatoriasPublicasYExclusivasDeDatarte',
						'PostulacionesIlimitadasATodasLasConvocatorias',
					],
				},
				redirect: '/',
			},
			{
				name: 'PlanPremium',
				role: 'ArtistaIndividual',
				price: '10 US$',
				duration: 'PorMes',
				benefits: {
					premium: [
						'ApoyoParaPresentarseALasConvocatorias',
						'MentoriasGrupalesSemanales',
						'AccesoACursosVirtualesMensualesDeAutogestion',
						'ConexionesConAgentesYNuevosPublicos',
						'PosibilidadDePostularParaSerPublicadoNuevosPortafolios',
					],
					common: [
						'AccesoALaWebApp',
						'InventarioDeObrasIlimitadas',
						'CreacionDePortafoliosIlimitados',
						'AccesoAConvocatoriasPublicasYExclusivasDeDatarte',
						'PostulacionesIlimitadasATodasLasConvocatorias',
					],
				},
				best_choice: true,
			},
			{
				name: 'PlanPremiumPlus',
				role: 'ArtistaIndividual',
				price: 'PrecioPorDemanda',
				duration: 'ContactoDatarte',
				benefits: {
					premium: [
						'ApoyoParaMaximizarLasPosibilidadesDeSerSeleccionadoEnLasConvocatorias',
						'MentoriaIndividual',
						'AccesoACursosVirtualesMensualesDeAutogestionYFormacion',
						'PosibilidadDePostularParaSerPublicadoNuevosPortafolios',
						'ConexionesDirectasConContactosDelEcosistemaDeLasArtesVisualesyOportunidades',
						'Representacion',
					],
					common: [
						'AccesoALaWebApp',
						'InventarioDeObrasIlimitadas',
						'CreacionDePortafoliosIlimitados',
						'AccesoAConvocatoriasPublicasYExclusivasDeDatarte',
						'PostulacionesIlimitadasATodasLasConvocatorias',
					],
				},
			},
		],
		annual: [
			{
				name: 'PlanPremium',
				role: 'ArtistaIndividual',
				price: '100 US$',
				duration: 'PorAño',
				benefits: {
					premium: [
						'ApoyoParaPresentarseALasConvocatorias',
						'MentoriasGrupalesSemanales',
						'AccesoACursosVirtualesMensualesDeAutogestionYFormacion',
						'ConexionesDirectasConContactosDelEcosistemaDeLasArtesVisualesyOportunidades',
						'PosibilidadDePostularParaSerPublicadoNuevosPortafolios',
					],
					common: [
						'AccesoALaWebApp',
						'InventarioDeObrasIlimitadas',
						'CreacionDePortafoliosIlimitados',
						'AccesoAConvocatoriasPublicasYExclusivasDeDatarte',
						'PostulacionesIlimitadasATodasLasConvocatorias',
					],
				},
			},
		],
	},
};
