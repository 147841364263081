import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClickableChipClick } from './ClickableChipClick';

export function FilterChipClick({ filterLabel, filterVariable }) {
	const { baseElements } = useSelector((state) => state.filter);

	const [filterChipInput] = useState('');
	const unique = [
		...new Set(baseElements.map((item) => item[filterVariable])),
	].sort();
	const chips = unique.filter((collection) => {
		if (collection === null || collection === undefined) {
			return '';
		} else {
			return collection.indexOf(filterChipInput) >= 0;
		}
	});
	return (
		<div className=''>
			<div>
				<div className='group mb-3 flex flex-row'>
					<label className='body-Text3' style={{ fontWeight: 600 }}>
						{' '}
						{filterLabel}{' '}
					</label>
				</div>
				{chips.length !== 1 ? (
					<div className='max-h-52 overflow-x-auto '>
						<div className=' h-8 w-full cursor-pointer rounded-md bg-middle_grey-50'>
							<ClickableChipClick
								chips={chips}
								filterVariable={filterVariable}
							/>
						</div>
					</div>
				) : (
					<label className='body-Text3' style={{ fontWeight: 600 }}>
						No hay elementos para filtrar
					</label>
				)}
			</div>
		</div>
	);
}
