export const messageDeleteElement = (
	section,
	info = [{ artworks: 0, images: 0, documents: 0, portfolios: 0 }]
) => {
	switch (section) {
		case 'artistas':
			return `También se eliminarán las imágenes (${info.images}), obras (${info.artworks}), colecciones (${info.collections}), portafolios (${info.portfolios}), documentos (${info.documents}) relacionados al artista.`;

		case 'obras':
			return `También se eliminarán las imágenes (${info.images}) relacionadas a la obra.`;

		case 'contactos':
			return `"También se eliminarán las imágenes (${info.images}) relacionadas al contacto."`;

		case 'documentos':
			return `También se eliminarán PDF, imágenes y videos relacionadas al documento.`;

		case 'colecciones':
			return `También se eliminarán los portafolios (${info.portfolios}) relacionados a esta colección.`;

		case 'lugares':
			return `También se eliminarán los espacios (${info.espacios}) e imágenes (${info.images}) relacionadas al lugar, las obras relacionadas a este lugar van a ser asignadas a un lugar desconocido.`;

		case 'salas':
			return `También se eliminarán las ubicaciones relacionadas con esta sala.`;

		case 'ubicacion':
			return '¿ Seguro que deseas eliminar esta ubicacion?';

		case 'portafolios':
			return '¿ Seguro que deseas eliminar este portafolio ?';

		default:
			return '';
	}
};
