//3rd
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// BL
import {
	cleanCollectionEdit,
	cleanNewCollection,
	getCollections,
} from '../../../BusinessLogic/redux/reducers/collection';
import {
	setSelectedArtworks,
	setSelectedDocuments,
} from '../../../BusinessLogic/redux/reducers/album';
import { getArtistDropdown } from '../../../BusinessLogic/redux/reducers/artist';
import {
	filterCollectionGalleryInitialValues,
	filterCollectionArtistInitialValues,
} from '../../../BusinessLogic/data/CollectionSingle';

// UI
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import BlankSpacesTemplate from '../../../UI/templates/BlankSpaces/BlankSpacesTemplate';
import collectionsImage from '../../../UI/assets/images/BlankSpacesImages/collectionsImg.jpg';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import FilterAllCollections from '../../../UI/molecules/Filters/FilterAllCollections';
import ImagesSection from '../../../UI/organisms/Image/ImagesSection';

export default function AllCollection() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { collections } = useSelector((state) => state.collections);
	const { artistDropdown } = useSelector((state) => state.artists);
	const { appUser } = useSelector((state) => state.appUser);

	const [changeView, setChangeView] = useState(false);
	const [collectionsToDisplay, setCollectionsToDisplay] = useState(collections);

	const isGalleryUser = appUser.role_company === 'GALLERY';

	function filterInitialValues() {
		if (isGalleryUser) {
			return filterCollectionGalleryInitialValues;
		} else {
			return filterCollectionArtistInitialValues;
		}
	}

	const [valuesFilterCollections, setValuesFilterCollections] = useState(
		filterInitialValues()
	);

	const handleFilterCollections = () => {
		if (collections) {
			let dataFiltered = collections;
			Object.keys(valuesFilterCollections)?.forEach((field) => {
				if (valuesFilterCollections[field] !== '') {
					if (field === 'collection') {
						dataFiltered = dataFiltered.filter((item) =>
							item?.name
								.toLowerCase()
								.includes(valuesFilterCollections[field].toLowerCase())
						);
					} else {
						dataFiltered = dataFiltered?.filter((item) => {
							if (typeof item[field] === 'number') {
								return item[field] === Number(valuesFilterCollections[field]);
							} else {
								return item[field] === valuesFilterCollections[field];
							}
						});
					}
				}
			});
			setCollectionsToDisplay(dataFiltered);
		}
	};

	useEffect(() => {
		handleFilterCollections();
		// eslint-disable-next-line
	}, [valuesFilterCollections]);

	useEffect(() => {
		if (!collections) {
			dispatch(getCollections());
		} else {
			setCollectionsToDisplay(collections);
		}

		if (!artistDropdown) {
			dispatch(getArtistDropdown());
		}
	}, [collections, dispatch, artistDropdown]);

	if (!collections) {
		return (
			<ViewAllSkeleton
				numberOfImages={collections?.length}
				designSkeletonTitle4={'invisible'}
				designSkeletonImages={
					'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
				}
			/>
		);
	}

	return (
		<TemplateWithNavBar design='md:overflow-x-visible'>
			{collections.code === 0 && collections.message && (
				<>
					<SecondaryNavbar
						title={t('Colecciones')}
						textColor='text-green-50'
						color='bg-green-50'
						redirect='home'
						hidden='invisible'
						showchangeView={false}
						showFilter={false}
					/>

					<div className='flex w-full flex-col items-center pb-8 md:pb-0'>
						<TextMainTitle
							title={t('Colecciones')}
							textColor=' text-green-50 md:hidden'
							color={'bg-green-50 w-[180px] md:hidden'}
						/>
					</div>
					<BlankSpacesTemplate
						title={'AhorraTiempo'}
						subtitle={'CreaTusColecciones'}
						text={'crearTusPropiasColecciones'}
						image={collectionsImage}
						message={collections.message}
						link={'/colecciones/new'}
					/>
				</>
			)}
			{!collections.message && collections.length > 0 && (
				<>
					<SecondaryNavbar
						title={t('Colecciones')}
						textColor='text-green-50'
						color='bg-green-50'
						redirect='home'
						hidden='invisible'
						showchangeView={false}
						showFilter={false}
					>
						<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterAllCollections
								valuesFilterCollections={valuesFilterCollections}
								setValuesFilterCollections={setValuesFilterCollections}
								valuesFilterInitialValues={filterInitialValues()}
							/>
						</div>
					</SecondaryNavbar>
					<div className='mb-5 flex h-12 w-full items-center justify-between px-4 md:hidden'>
						<div className='invisible w-16'></div>
						<TextMainTitle
							title={t('Colecciones')}
							textColor='text-green-50'
							color='bg-green-50'
						/>
						<div className='flex flex-row'>
							<ButtonChangeView
								changeView={changeView}
								setChangeView={setChangeView}
							/>
							<FilterAllCollections
								valuesFilterCollections={valuesFilterCollections}
								setValuesFilterCollections={setValuesFilterCollections}
								valuesFilterInitialValues={filterInitialValues()}
							/>
						</div>
					</div>
					<ImagesSection
						data={collectionsToDisplay ?? []}
						redirect='colecciones'
						changeView={changeView}
						isDataCollection={true}
						emptyMessage={t('NoHayResultados')}
						design='px-0 xs:px-4'
					/>
				</>
			)}

			<div
				onClick={() => {
					dispatch(cleanCollectionEdit());
					dispatch(setSelectedArtworks([]));
					dispatch(setSelectedDocuments([]));
					dispatch(cleanNewCollection());
				}}
			>
				<ButtonFabPrincipalUrl label={t('Crear')} url='/colecciones/new' />
			</div>
		</TemplateWithNavBar>
	);
}
