import { removeUserSession } from '../../../../Pages/authentication/servicesAuthentication';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * It's a button that when clicked, removes the user's session
 * @returns
 */
export const ButtonSignOut = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<button
			className={'body-Text2'}
			onClick={() => removeUserSession(navigate)}
		>
			{t('CerrarSesión')}
		</button>
	);
};
