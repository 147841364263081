//3rd parties
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { resetHistoryURL } from '../../../BusinessLogic/redux/reducers/navigation';

//UI
import logoDatarte from '../../../UI/assets/icons/logoDatarte.svg';
import ManagamentMenuPerfil from '../../organisms/Managament/ManagamentMenuPerfil';
import ItemSectionNavbarDesktop from '../NavBarItems/ItemSectionNavbarDesktop';
import MainLogoDesktop from '../MainLogoDesktop';
import PopupSmall from '../../organisms/PopUp/PopupSmall';
import ButtonWithIcon from '../Buttons/WithIcon/ButtonWithIcon';
import SelectorLanguage from '../Selector/SelectorLanguaje';
import FigureIconPerfil from '../../atoms/Figure/FigureIconPerfil';
import { ReactComponent as Location } from '../../assets/icons/Location.svg';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

/**
 * @description render Navbar desktop with the logo, section selection,
 * perfil settings and language selection.
 * @returns
 */
export default function NavbarDesktop({
	section,
	options,
	normalState,
	rotateState,
}) {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const [perfil, setPerfil] = useState(false);
	const [subOptions, setSubOptions] = useState(false);
	const [subMenuItems, setSubMenuItems] = useState(['hello']);
	const [indexItem, setIndexItem] = useState(0);

	const handleCloseMenu = () => {
		setSubOptions(false);
		dispatch(resetHistoryURL());
	};
	return (
		<div className='hidden h-12 md:block'>
			<nav className='flex h-full max-w-full flex-row items-center justify-between bg-green-50 px-5 md:pr-0 lg:px-5'>
				<MainLogoDesktop
					image={logoDatarte}
					alt='datarte.art'
					action={() => handleCloseMenu()}
					design='w-1/4'
				/>
				<div className='flex w-full flex-row justify-between gap-2 text-center text-white'>
					{options.map((item, index) => (
						<div className='flex flex-col' key={index}>
							<ItemSectionNavbarDesktop
								option={t(item.name)}
								url={item.url}
								subItems={item.subItems}
								section={section}
								subOptions={subOptions}
								setSubOptions={setSubOptions}
								setSubMenuItems={setSubMenuItems}
								index={index}
								setIndexItem={setIndexItem}
								indexItem={indexItem}
								normalState={normalState}
								rotateState={rotateState}
								closeMenu={() => handleCloseMenu()}
							/>
							{subOptions && index === indexItem && (
								<PopupSmall design='bg-green-50 py-5 w-max mt-1 gap-4'>
									{subMenuItems.map((subItem, i) => (
										<Link key={i} to={subItem.url}>
											<div className='flex cursor-pointer items-center hover:bg-light_grey-100'>
												<div
													className='flex w-full flex-row items-center px-5'
													onClick={() => handleCloseMenu()}
												>
													<ButtonWithIcon
														icon={subItem.icon}
														design='mr-4'
														setShowBoolean={setSubOptions}
													/>
													<TextContentPrincipal
														text={t(subItem.name)}
														design='text-white'
													/>
												</div>
											</div>
										</Link>
									))}
								</PopupSmall>
							)}
						</div>
					))}
				</div>
				<div className='flex w-1/4 flex-row items-center justify-end  gap-2'>
					<Location className='ml-2 md:ml-0' />
					<SelectorLanguage
						languageList={['ES', 'EN']}
						popupDesign='pt-3 pb-4 items-center text-white bg-green-50 w-28 -right-1 rounded-lg'
					/>

					<FigureIconPerfil
						design='flex cursor-pointer mx-2 pl-6 md:mx-0'
						action={() => setPerfil(!perfil)}
					/>
				</div>
			</nav>
			{perfil && <ManagamentMenuPerfil action={() => setPerfil(false)} />}
		</div>
	);
}

NavbarDesktop.propTypes = {
	/** array of objects that contents the name of the option and nested options */
	option: PropTypes.string,
	/** Compares with the string to know if has been selected */
	section: PropTypes.string,
	/** function change the state of section to other section when is clicked */
	setSection: PropTypes.func,
	/** normal direction of the arrow */
	normalState: PropTypes.string,
	/** change direction of the arrow */
	rotateState: PropTypes.string,
};
