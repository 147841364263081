// 3rd parties
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// UI
import TextSingleRowNew from '../../molecules/Text/TextSingleRowNew';
import TextSubTitle from '../../molecules/Text/TextSubTitle';
import CollectionsButton from '../../atoms/Text/Labels/CollectionsButton';
import CirclePerfil from '../../atoms/Figure/FigureCirclePerfil';
import { isValid, format } from 'date-fns';
import { useSelector } from 'react-redux';

/**
 * @description render information about the section with a title of this, label and full description
 * @param {*} param0
 * @returns
 */

export default function SectionTitleAndFields({
	sectionTitle,
	information,
	colorHr,
	design,
	setTurnEdit,
	turnEdit,
	section,
	hideEditOption,
}) {
	const { appUser } = useSelector((state) => state.appUser);
	const { cities, countries } = useSelector((state) => state.staticData);

	const { t } = useTranslation();
	const location = useLocation();
	const isArtistUser = appUser.role_company === 'ARTIST';
	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';

	const informationWithShortDate = information.map((item) => {
		const isValidArtistArtworkYearDate =
			item.label === 'Año' &&
			location.pathname.includes('obras') &&
			(isArtistUser || isGalleryUser) &&
			isValid(new Date(item.value));

		const isValidCatalogueArtistArtworkYearDate =
			item.label === 'Año' &&
			location.pathname.includes('obras') &&
			isCatalogueArtistUser &&
			isValid(new Date(item.value));

		const isValidParsedDateInField =
			(item.type === 'date' || item.label === 'Año') &&
			item.value &&
			Date.parse(item.value);

		if (isValidParsedDateInField) {
			const ISOdate = new Date(Date.parse(item.value))
				.toISOString()
				.substring(0, 10);

			const shortDate = ISOdate.split('-').reverse().join('/');
			if (
				isValidArtistArtworkYearDate ||
				isValidCatalogueArtistArtworkYearDate
			) {
				return {
					...item,
					value: format(new Date(item.value), 'yyyy'),
				};
			} else {
				return {
					...item,

					value: shortDate,
				};
			}
		}
		return item;
	});

	const textCheckboxValues = (item) => {
		let text = '';
		if (Array.isArray(item.options)) {
			item?.value?.forEach((value) => {
				const option = item.options.find((op) => op.id === String(value));

				if (option) {
					text += (text.length > 0 ? ', ' : '') + option.label;
				}
			});

			return text;
		}
	};

	const renderSwitch = (item, i) => {
		switch (item?.type) {
			case 'checkbox':
				return (
					<TextSingleRowNew
						label={t(item.label)}
						value={textCheckboxValues(item)}
						labelWeight={{ fontWeight: 400 }}
						key={i}
					/>
				);

			case 'collections':
				return (
					<div key={i}>
						<TextSingleRowNew
							label={t(item.label)}
							labelWeight={{ fontWeight: 400 }}
						/>
						{item.value.length > 0 && (
							<div div className='flex flex-row flex-wrap gap-2'>
								{item.value.map((value, i) => (
									<CollectionsButton key={i} label={value.collection_name} />
								))}
							</div>
						)}
					</div>
				);

			case 'dropdown': {
				let optionValue;
				if (Array.isArray(item.options)) {
					const optionSelected = item.options.find(
						(opt) => String(opt.value) === String(item.value)
					);
					optionValue = optionSelected?.label;
				} else {
					if (item.field === 'country_id') {
						if (Array.isArray(countries)) {
							optionValue = countries?.find(
								(country) => country.value === item.value
							)?.label;
						}
					}
					if (item.field === 'city_id' && Array.isArray(cities)) {
						optionValue = cities?.find(
							(city) => city.value === item.value
						)?.label;
					}
				}
				return (
					<TextSingleRowNew
						label={t(item.label)}
						value={optionValue}
						labelWeight={{ fontWeight: 400 }}
						key={i}
					/>
				);
			}

			case 'roles_assigned': {
				return (
					<div className='mb-6 flex flex-col' key={i}>
						<div className='flex flex-row gap-4'>
							<div className='w-fit-content'>
								<CirclePerfil
									initial={item.first_letter}
									design='flex bg-green-50 text-white rounded-full h-11 w-11 items-center justify-center'
								/>
							</div>
							<div className='flex w-4/5 flex-col md:w-full'>
								{item.userData.map((data, index) => (
									<TextSingleRowNew
										label={t(data.label)}
										value={data.value_name}
										labelWeight={{ fontWeight: 400, paddingRight: '4px' }}
										key={index}
									/>
								))}
							</div>
						</div>
						<hr />
					</div>
				);
			}
			case 'document_belongs':
				return (
					<TextSingleRowNew
						label={t(item.label)}
						value={item.value === 'NOT_ELEMENT' ? t('Ninguno') : item.value}
						labelWeight={{ fontWeight: 400 }}
						key={i}
						section={section}
					/>
				);

			default:
				return (
					<TextSingleRowNew
						label={t(item.label)}
						value={sectionTitle === 'Contraseña' ? '*********' : item.value}
						labelWeight={{ fontWeight: 400 }}
						key={i}
						section={section}
					/>
				);
		}
	};

	return (
		<div className={`flex w-full flex-col ${design}`}>
			<TextSubTitle
				text={sectionTitle}
				colour={colorHr}
				hasAdd={false}
				setTurnEdit={setTurnEdit}
				turnEdit={turnEdit}
				hasEdit={!hideEditOption}
			/>
			<div className='flex flex-col'>
				{Array.isArray(informationWithShortDate) &&
					informationWithShortDate?.map((item, i) => {
						return renderSwitch(item, i);
					})}
			</div>
		</div>
	);
}

SectionTitleAndFields.propTypes = {
	/* title of the section with information */
	sectionTitle: PropTypes.string,
	/* objects array with keys and values of each element  */
	information: PropTypes.array,
	/** Color of Header in Subtitle */
	colorHr: PropTypes.string,
	/** Tailwind classes for design */
	design: PropTypes.string,
	/** Trigger a function when the edit is clicked */
	setTurnEdit: PropTypes.func,
	/** Trigger edit mode in the section */
	turnEdit: PropTypes.bool,
	/* Identify in which single section SectionTitleAndFields component is being used */
	section: PropTypes.string,
};
