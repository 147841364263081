import * as Yup from 'yup';

export const singleCallFormValidationSchema = Yup.object({
	accepts_tcs: Yup.boolean().oneOf([true], 'PolíticasSeguridadRequerido'),
	name: Yup.string().required('El título es requerido'),
	sub_header: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	header: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	requirements: Yup.string().max(10000, 'NumeroMáximoCaracteres').nullable(),
	route_slug: Yup.string()
		.min(2, 'RequiereDosCaracteres')
		.required('UrlObligatorio')
		.matches(
			/^[a-z0-9]+(?:[-,_][a-z0-9]+)*$/,
			'Únicamente caracteres alfanuméricos y guiones.'
		),
	call_organizer: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			description: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			email: Yup.string().email('CorreoValido').nullable(),
			phone: Yup.string().min(7, 'TeléfonoValido').nullable(),
			website: Yup.string().nullable(),
			address: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			postal_code: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		})
	),
});
