import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ImagePayments from '../../assets/images/ImagePayments.jpg';

import AuthenticationTemplate from '../../templates/AuthenticationTemplate';
import { paymentsOptions } from '../../../BusinessLogic/data/paymentsOptions';
import PaymentCard from './PaymentCard';

export default function Payments() {
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);
	const navigate = useNavigate();
	const userRole = appUser.role_company.toLowerCase();

	const [paymentType, setPaymentType] = useState('monthly');
	const paymentsOptionByPaymentType =
		paymentsOptions?.[userRole]?.[paymentType];

	const onSwitchChange = () => {
		if (paymentType === 'monthly') {
			setPaymentType('annual');
		} else {
			setPaymentType('monthly');
		}
	};

	useEffect(() => {
		if (userRole !== 'artist') {
			navigate('/');
		}
	}, [userRole, navigate]);

	return (
		<AuthenticationTemplate image={ImagePayments}>
			<div className='mx-auto mt-3  text-center md:px-5 '>
				<h2 className=' text-xl font-bold text-green-50'>
					{t('CreaTuCuentaDeArtista')}
				</h2>
				<h3 className=' text-base font-light text-green-50'>
					{t('SeleccionaUnPlan')}
				</h3>
				<div className='my-5 flex items-center justify-center gap-5'>
					<span className=' text-sm font-extralight  text-black-50'>
						{t('PlanMensual')}
					</span>
					<label className='relative inline-flex cursor-pointer items-center'>
						<input type='checkbox' value='' className='peer sr-only' />
						<div
							className="peer h-6 w-11 rounded-full bg-grey-50 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-50  peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none"
							onClick={onSwitchChange}
						></div>
					</label>
					<span className=' text-sm font-extralight  text-black-50'>
						{t('PlanAnual')}
					</span>
				</div>
				<div className=' space-y-14 overflow-y-auto px-4 py-5 '>
					{paymentsOptionByPaymentType?.map((payment, index) => (
						<PaymentCard key={index} {...payment} />
					))}
				</div>
				<div className='mt-5 px-4 text-start text-base'>
					<h4 className=' mb-2  font-bold text-green-50 underline'>
						{t('TienesUnCupondeDescuento')}
					</h4>
					<p className=' text-xs font-light text-black-50'>
						{t('QuieresObtenerUnCuponDeDescuento')}
						<a className='underline' href='mailto:contacto@datarte.art'>
							contacto@datarte.art
						</a>
					</p>
				</div>
			</div>
		</AuthenticationTemplate>
	);
}
