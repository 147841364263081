export const PAYMENTS_EN = {
	CreaTuCuentaDeArtista: 'Create your artist account',
	SeleccionaUnPlan: 'Select a plan',

	PlanMensual: 'Monthly plan',
	PlanAnual: 'Annual plan',

	TienesUnCupondeDescuento: 'Do you have a discount coupon?',
	QuieresObtenerUnCuponDeDescuento:
		'Do you want to get a discount coupon? You can get a free account by partnering with us. We partner with other art organizations to increase the impact of calls. Contact us expressing your partnership motivation at the email ',

	MejorOferta: 'Best offer',
	Suscribirse: 'Subscribe',

	ParaArtistasQueDeseanOportunidades:
		'For artists who want to find new opportunities and apply to calls',
	EstoIncluye: 'This includes:',

	AplicanTerminosCondiciones: '*terms and conditions apply',
	EstaSuscripcionSeraRenovada:
		'*this subscription will be automatically renewed every year.',

	PlanGratis: 'Free plan',
	PlanPremium: 'Premium plan',
	PlanPremiumPlus: 'Premium Plus plan',

	ArtistaIndividual: 'Individual artist',

	PorAño: 'per year',
	PorSiempre: 'forever',
	PorMes: 'per month',
	PrecioPorDemanda: '*Price on demand',
	ContactoDatarte: 'at contact@datarte.art',

	MentoriasGrupalesSemanales:
		'Weekly group mentoring for a maximum of 5 people',
	AccesoACursosVirtualesMensualesDeAutogestion:
		'Access to monthly virtual self-management courses for more professionalism',
	AccesoACursosVirtualesMensualesDeAutogestionYFormacion:
		'Access to monthly virtual self-management and training courses',

	ApoyoParaPresentarseALasConvocatorias: 'Support to apply for calls',
	ApoyoParaMaximizarLasPosibilidadesDeSerSeleccionadoEnLasConvocatorias:
		'Support to maximize the chances of being selected in calls',
	ConexionesConAgentesYNuevosPublicos:
		'Connections with agents and new audiences to develop the artistic career',
	MentoriaIndividual: 'Individual mentoring',
	PosibilidadDePostularParaSerPublicadoNuevosPortafolios:
		'Possibility to apply to have your portfolio published in Nuevos Portafolios and on our social networks',
	ConexionesDirectasConContactosDelEcosistemaDeLasArtesVisualesyOportunidades:
		'Direct connections with contacts in the visual arts ecosystem and opportunities',
	Representacion: 'Representation',

	AccesoALaWebApp: 'Access to the web app',
	InventarioDeObrasIlimitadas: 'Unlimited artwork inventory',
	CreacionDePortafoliosIlimitados: 'Creation of unlimited portfolios',
	AccesoAConvocatoriasPublicasYExclusivasDeDatarte:
		'Access to public calls and Datarte exclusives (not available anywhere else)',
	PostulacionesIlimitadasATodasLasConvocatorias:
		'Unlimited applications to all calls',
};
