/* -- Third Party -- */
import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//BL
import {
	artworkInitialValues,
	artworkFormValidationSchemaArtistSimple,
	artworkObjectTypes,
	artworkResolutions,
	artworkAspects,
	artworkVideoFormValidationSchema,
} from '../../../../BusinessLogic/data/ArtworkSingleData';

import {
	getArtworks,
	postArtwork,
} from '../../../../BusinessLogic/redux/reducers/artwork';
import { backSectionURL } from '../../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	getCollections,
	resetCollectionSingle,
} from '../../../../BusinessLogic/redux/reducers/collection';
import { getPortfolios } from '../../../../BusinessLogic/redux/reducers/portfolio';
import {
	getArtist,
	getArtistDropdown,
} from '../../../../BusinessLogic/redux/reducers/artist';
import {
	getCountries,
	getCurrencies,
} from '../../../../BusinessLogic/redux/reducers/staticData';

/* UI */
import whiteCheck from '../../../../UI/assets/icons/whiteCheck.svg';
import ListItemText from '../../../../UI/atoms/Inputs/ListItemText';
import FieldTextFormik from '../../../../UI/molecules/Field/FieldTextFormik';
import FieldTextAreaFormik from '../../../../UI/molecules/Field/FieldTextAreaFormik';
import SecondaryNavbarSpecific from '../../../../UI/organisms/Navbar/NavbarSecondarySpecific';
import ImageSectionForm from '../../../../UI/organisms/Image/ImageSectionForm';
import SectionForm from '../../../../UI/organisms/Section/SectionForm';
import ButtonFabExtendedPrimary from '../../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import ViewForm from '../../../../UI/templates/ViewForm';
import LoadingText from '../../../../UI/molecules/Loading/LoadingText';
import VideoPlaceholder from '../../../../UI/molecules/Video/VideoPlaceholder';
import TextContentThird from '../../../../UI/atoms/Text/Content/TextContentThird';
import FieldSelectFormik from '../../../../UI/molecules/Field/FieldSelectFormik';

/**
 * @description edit and create new artworks
 * @param {*} param0
 * @returns
 */

export default function ArtworkSimplifiedForm() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { appUser } = useSelector((state) => state.appUser);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { countries, currencies } = useSelector((state) => state.staticData);

	const { artistDropdown } = useSelector((state) => state.artists);
	const [showSaving, setShowSaving] = useState(false);

	const videoRef = useRef(null);

	const [artworkType, setArtworkType] = useState('BI-DIMENSIONAL');
	const isGalleyUser = appUser.role_company === 'GALLERY';
	const isVideoArtwork =
		artworkType === 'VIDEO' ||
		artworkType === 'PERFORMANCE' ||
		artworkType === 'ANIMATION';

	const currentYear = new Date().getFullYear();

	const mainArtistId = appUser?.main_artist_id;

	const initialValues = {
		...artworkInitialValues,
		type: 'ARTWORK',
		type_id: 0,
		artist_id: isGalleyUser ? '' : appUser.main_artist_id,
		file_type: isVideoArtwork ? 'VIDEO' : 'IMAGE',
		created_by_id: appUser?.organization_user_id,
		organization_user_id: appUser?.organization_user_id,
		origin_country_id: 47,
		date: currentYear,
		currency_id: '5',
		object_type: artworkType,
		video_url: '',
		duration: null,
		holder: '',
		resolution: isVideoArtwork ? artworkResolutions[0].value : '',
		aspect_ratio: isVideoArtwork ? artworkAspects[0].value : '',
		serial_number: '',
	};

	useEffect(() => {
		if (!countries) {
			dispatch(getCountries());
		}
		if (!currencies) {
			dispatch(getCurrencies());
		}
	}, [countries, currencies, dispatch]);

	useEffect(() => {
		dispatch(getArtistDropdown());
	}, [dispatch]);

	const checkRedirect = () => {
		let redirect = '/obras';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage =
			navigationHistoryURL[navigationHistoryURL.length - 1] === '/'
				? 'RegresarHome'
				: 'RegresarObras';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const handleOnsubmit = (values) => {
		setShowSaving(true);
		if (values.date !== '') {
			values.date = new Date(values.date, 1, 1);
		}

		dispatch(postArtwork(values))
			.then((res) => {
				const newArtworkId = res?.payload?.artwork?.id;
				if (newArtworkId) {
					navigate(`/obras/${newArtworkId}`);
				}

				dispatch(getArtworks());
				dispatch(getCollections());
				dispatch(resetCollectionSingle());
				dispatch(getPortfolios());
				dispatch(getArtist({ id: mainArtistId }));
			})
			.finally(() => setShowSaving(false));
	};

	return (
		<ViewForm>
			<div className={showSaving && 'opacity-60'}>
				<SecondaryNavbarSpecific
					title={t('NuevaObra')}
					textColor='text-yellow-50'
					color='bg-yellow-50'
					hide='md:invisible'
					redirect={checkRedirect()}
					textBack={CheckTextBack()}
					textBackDesign='pl-3 text-yellow-50'
					arrowLeftColor='yellow'
					backAction={() => returnBackAction()}
				/>

				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={
						isVideoArtwork
							? artworkVideoFormValidationSchema
							: artworkFormValidationSchemaArtistSimple
					}
					onSubmit={handleOnsubmit}
				>
					{(formProps) => (
						<Form className='mb-20'>
							<List>
								<div className='mx-auto flex w-11/12 flex-col justify-start gap-4 md:flex-row lg:w-4/5 lg:gap-12'>
									{isVideoArtwork ? (
										<div className='flex aspect-square w-full flex-col self-start overflow-hidden md:w-1/2'>
											{formProps.values.video_url ? (
												<ReactPlayer
													ref={videoRef}
													controls
													url={formProps.values.video_url}
													onDuration={(duration) =>
														formProps.setFieldValue('duration', duration)
													}
													width={'100%'}
													height={'100%'}
												/>
											) : (
												<VideoPlaceholder text={t('ArchivosPermitidosVideo')} />
											)}
										</div>
									) : (
										<ImageSectionForm
											formProps={formProps}
											dropzoneLabel={'SoltarOClickImagenPortadaObra'}
											mobileDropzoneLabel={'ClickCargarImagenPortadaObra'}
										/>
									)}

									<div className='mx-auto flex w-full flex-col md:w-1/2'>
										<SectionForm
											title={t('Identificación')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Título')}
												dbName='name'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.name}
											/>
										</SectionForm>

										<ListItem
											style={{
												paddingLeft: '0px',
												paddingRight: '0px',
												justifyContent: 'space-between',
											}}
										>
											<ListItemText
												primary={t('Tipo')}
												design='body-Text2 -mt-8'
												style={{ fontWeight: 400 }}
											/>
											<select
												name='artwork_type'
												className='body-Text2 -mt-8 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												value={artworkType}
												onChange={(e) => {
													setArtworkType(e.target.value);
													formProps.setFieldValue(
														'object_type',
														e.target.value
													);
												}}
											>
												{artworkObjectTypes.map((option) => (
													<option value={option.value}>
														{t(option.label)}
													</option>
												))}
											</select>
										</ListItem>
										{formProps.errors?.object_type && (
											<div className={`w-40 text-red-400 md:w-56`}>
												{t(formProps.errors?.object_type)}
											</div>
										)}

										{isVideoArtwork && (
											<>
												<ListItem
													style={{
														display: 'flex',
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('URLVideo')}
														design='body-Text2'
														style={{ fontWeight: 400 }}
													/>
													<div
														className={
															formProps.errors?.video_url ? 'mb-8' : ''
														}
													>
														<Field
															className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
															name='video_url'
															type='text'
														/>
														{formProps.errors?.video_url && (
															<div
																className={`absolute w-40 text-red-400 md:w-56`}
															>
																{t(formProps.errors?.video_url)}
															</div>
														)}
													</div>
												</ListItem>
												<TextContentThird
													text={t('ArchivosPermitidosVideo')}
													design={'text-grey-50 mb-2'}
												/>
												<ListItem
													style={{
														display: 'flex',
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('Duración')}
														design='body-Text2'
														style={{ fontWeight: 400 }}
													/>
													<div>
														<Field
															className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
															name='duration'
															type='number'
															min={0}
														/>
														{formProps.errors?.duration && (
															<div
																className={`absolute w-40 text-red-400 md:w-56`}
															>
																{t(formProps.errors?.duration)}
															</div>
														)}
													</div>
												</ListItem>
											</>
										)}

										<div className='mb-8'>
											<ListItem
												style={{
													display: 'flex',
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													primary={t('Año')}
													design='body-Text2'
													style={{ fontWeight: 400 }}
												/>
												<div>
													<Field
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														name='date'
														type='number'
														min='1900'
														max={currentYear}
														step='1'
														placeholder='YYYY'
													/>
													{formProps.errors?.date && (
														<div
															className={`absolute w-40 text-red-400 md:w-56`}
														>
															{t(formProps.errors?.date)}
														</div>
													)}
												</div>
											</ListItem>

											{isVideoArtwork && (
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('PaísDeOrigen')}
														design='body-Text2'
														style={{ fontWeight: 400 }}
													/>
													<div className='flex flex-col'>
														<Field
															name='origin_country_id'
															type='Text'
															as='select'
															className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														>
															<option value=''></option>
															{countries?.map((country) => (
																<option
																	value={country.value}
																	key={country.value}
																>
																	{country.label}
																</option>
															))}
														</Field>
														{formProps.errors?.origin_country_id && (
															<div className={`w-40 text-red-400 md:w-56`}>
																{t(formProps.errors?.origin_country_id)}
															</div>
														)}
													</div>
												</ListItem>
											)}
										</div>

										<SectionForm
											title={t('Descripción')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextAreaFormik
												label='description'
												dbName='description'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.description}
											/>
										</SectionForm>

										{!isVideoArtwork && (
											<SectionForm
												title={t('DimensionesObjeto')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<FieldTextFormik
													label={t('AlturaCm')}
													dbName='dimensions_height'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_height}
												/>
												<FieldTextFormik
													label={t('LargoCm')}
													dbName='dimensions_length'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_length}
												/>
												<FieldTextFormik
													label={t('AnchoCm')}
													dbName='dimensions_width'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_width}
												/>
												<FieldTextFormik
													label={t('PesoKg')}
													dbName='dimensions_weight'
													type='Number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.dimensions_weight}
												/>
											</SectionForm>
										)}

										{isVideoArtwork && (
											<SectionForm
												title={t('AutorVideo')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
												containerDesign={'gap-2'}
											>
												<FieldTextFormik
													label={t('AutorVideo')}
													dbName='holder'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.holder}
												/>
											</SectionForm>
										)}

										{isVideoArtwork && (
											<SectionForm
												title={t('Dimensiones')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
												containerDesign={'gap-2'}
											>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('Resolución')}
														design='body-Text2'
														style={{ fontWeight: 400 }}
													/>
													<Field
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														as='select'
														name='resolution'
													>
														{artworkResolutions.map((option) => (
															<option value={option.value}>
																{option.label}
															</option>
														))}
													</Field>
												</ListItem>
												<ListItem
													style={{
														paddingLeft: '0px',
														paddingRight: '0px',
														justifyContent: 'space-between',
													}}
												>
													<ListItemText
														primary={t('Aspecto')}
														design='body-Text2'
														style={{ fontWeight: 400 }}
													/>
													<Field
														className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
														as='select'
														name='aspect_ratio'
													>
														{artworkAspects.map((option) => (
															<option value={option.value}>
																{option.label}
															</option>
														))}
													</Field>
												</ListItem>
												<FieldTextFormik
													label={t('NúmeroDeSerie')}
													dbName='serial_number'
													errorMessageDesign={`text-red-400`}
													errors={formProps.errors?.serial_number}
												/>
											</SectionForm>
										)}

										<SectionForm
											title={t('TécnicaMateriales')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Técnica')}
												dbName='technique'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.technique}
											/>

											<FieldTextFormik
												label={t('Materiales')}
												dbName='materials'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.materials}
											/>
										</SectionForm>

										<SectionForm
											title={t('Avalúo')}
											textColor='text-yellow-50'
											color='bg-yellow-50'
										>
											<FieldTextFormik
												label={t('Avalúo')}
												dbName='price'
												type='Text'
												errorMessageDesign={`text-red-400`}
												errors={formProps.errors?.price}
											/>
											<ListItem
												style={{
													paddingLeft: '0px',
													paddingRight: '0px',
													justifyContent: 'space-between',
												}}
											>
												<ListItemText
													design='body-Text2'
													primary={t('Moneda')}
													style={{ fontWeight: 400 }}
												/>
												<Field
													name='currency_id'
													type='text'
													as='select'
													className='body-Text2 h-6 w-40 rounded border-none bg-light_grey-50 md:w-56'
												>
													{currencies?.map((currency) => (
														<option key={currency.id} value={currency.value}>
															{currency.label}
														</option>
													))}
												</Field>
											</ListItem>
											{formProps.errors?.valuation_number && (
												<div className={`w-40 text-red-400 md:w-56`}>
													{t(formProps.errors?.valuation_number)}
												</div>
											)}
										</SectionForm>
										{isGalleyUser && (
											<SectionForm
												title={t('Autor')}
												textColor='text-yellow-50'
												color='bg-yellow-50'
											>
												<FieldSelectFormik
													label={t('Artista')}
													dbName='artist_id'
													options={artistDropdown?.allArtist}
													errors={
														formProps.errors && formProps.errors.artist_id
													}
													designErrors='text-right'
												/>
											</SectionForm>
										)}

										{showSaving ? (
											<LoadingText text={t('Guardando')} />
										) : (
											<ButtonFabExtendedPrimary
												className='mx-auto text-white'
												icon={whiteCheck}
												text={t('Guardar')}
												typeButton='submit'
											/>
										)}
									</div>
								</div>
							</List>
						</Form>
					)}
				</Formik>
			</div>
		</ViewForm>
	);
}
