// 3rd party
import PropTypes from 'prop-types';
import ViewCityCountry from '../View/ViewCityCountry';
import ViewLocation from '../View/ViewLocation';
import ViewPerfilPassword from '../View/ViewPerfilPassword';
import ViewPortfolio from '../View/ViewPortfolio';
import ViewDocument from '../View/ViewDocument';
import { useTranslation } from 'react-i18next';
// UI
import ViewTitleAndFields from '../View/ViewTitleAndFields';
import ViewTitleAndText from '../View/ViewTitleAndText';
import ViewConfigurationRoles from '../View/ViewConfigurationRoles';

/**
 * @description renders a tab with a Information section (labels or text) or its edit version
 * @param {*} param0
 * @returns
 */

const TabInfoAndEdit = ({
	information,
	onSubmitAction,
	indexInfo,
	colorHr,
	id,
	validationSchema,
	section,
	hideEdit,
	hasInviteUser,
	usersOrganizationInformation,
	confirmDeleteElement,
}) => {
	const { t } = useTranslation();

	return (
		<div className='flex flex-col bg-light_grey-50 p-4 px-8'>
			{information.content.map((tab, i) => {
				if (tab.type === 'labels') {
					return (
						<ViewTitleAndFields
							colorHr={colorHr}
							sectionTitle={t(tab.title)}
							information={tab.information}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							validationSchema={validationSchema[i] ? validationSchema[i] : []}
							id={id}
							key={i}
							section={section}
							hideEditOption={tab?.hideEditOption}
						/>
					);
				} else if (tab.type === 'text') {
					return (
						<ViewTitleAndText
							sectionTitle={t(tab.title)}
							information={tab.information}
							field={tab.field}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							validationSchema={
								validationSchema[i] ? validationSchema[i] : null
							}
							key={i}
							id={id}
							hideEdit={hideEdit}
							hasInviteUser={hasInviteUser}
						/>
					);
				} else if (tab.type === 'location') {
					return (
						<ViewLocation
							indexInfo={indexInfo}
							information={tab.information}
							sectionTitle={t(tab.title)}
							colorHr={colorHr}
							onSubmitAction={onSubmitAction}
							validationSchema={
								validationSchema[i] ? validationSchema[i] : null
							}
							key={i}
							id={id}
						/>
					);
				} else if (tab.type === 'cityCountry') {
					return (
						<ViewCityCountry
							sectionTitle={t(tab.title)}
							information={tab.information}
							field={tab.field}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							validationSchema={
								validationSchema[i] ? validationSchema[i] : null
							}
							key={i}
							id={id}
						/>
					);
				} else if (tab.type === 'portfolio') {
					return (
						<ViewPortfolio
							sectionTitle={t(tab.title)}
							information={tab.information}
							field={tab.field}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							validationSchema={
								validationSchema[i] ? validationSchema[i] : null
							}
							key={i}
							id={id}
							section={section}
						/>
					);
				} else if (tab.type === 'documents') {
					return (
						<ViewDocument
							sectionTitle={t(tab.title)}
							information={tab.information}
							field={tab.field}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							key={i}
							id={id}
						/>
					);
				} else if (tab.type === 'password') {
					return (
						<ViewPerfilPassword
							sectionTitle={t(tab.title)}
							information={tab.information}
							field={tab.field}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							validationSchema={
								validationSchema[i] ? validationSchema[i] : null
							}
							key={i}
							id={id}
						/>
					);
				} else if (tab.type === 'rolesAssigned') {
					return (
						<ViewConfigurationRoles
							sectionTitle={t(tab.title)}
							information={usersOrganizationInformation}
							colorHr={colorHr}
							indexInfo={indexInfo}
							onSubmitAction={onSubmitAction}
							confirmDeleteElement={confirmDeleteElement}
							key={i}
							id={id}
						/>
					);
				} else return null;
			})}
		</div>
	);
};

export default TabInfoAndEdit;

TabInfoAndEdit.propTypes = {
	/* object with title and content of the tab*/
	information: PropTypes.object,
	/* number that representes the tab that is displayed */
	indexInfo: PropTypes.number,
	/* color used in title and hr in the section */
	color: PropTypes.string,
	/* id of the element */
	id: PropTypes.number,
	/* ValidationSchema used to manage error messages */
	validationSchema: PropTypes.array,
	/* identify in which section single is using */
	section: PropTypes.string,
	/* shows or not the Edit feature */
	hideEdit: PropTypes.bool,
	/* handle the feature to invite a user in the field section */
	hasInviteUser: PropTypes.bool,
	/* data that only uses in case of role users of the organization */
	usersOrganizationInformation: PropTypes.array,
};

TabInfoAndEdit.defaultProps = {
	color: 'purple',
	section: '',
	hideEdit: false,
	hasInviteUser: false,
	usersOrganizationInformation: [{}],
};
