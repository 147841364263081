export const PAYMENTS_ES = {
	CreaTuCuentaDeArtista: 'Crea tu cuenta de artista',
	SeleccionaUnPlan: 'Selecciona un plan',

	PlanMensual: 'Plan mensual',
	PlanAnual: 'Plan anual',

	TienesUnCupondeDescuento: '¿Tienes un cupón de descuento?',
	QuieresObtenerUnCuponDeDescuento: `¿Quieres obtener un cupón de descuento? Puedes conseguir una cuenta
    en Datarte gratuita aliándote con nosotros. Nos aliamos con otras
    organizaciones de arte para aumentar el impacto de las
    convocatorias. Contáctanos expresando tu motivación de alianza al
    correo electrónico `,

	MejorOferta: 'Mejor oferta',
	Suscribirse: 'Suscribirse',

	ParaArtistasQueDeseanOportunidades:
		'Para artistas que desean encontrar nuevas oportunidades y postularse a convocatorias',
	EstoIncluye: 'Esto incluye:',

	AplicanTerminosCondiciones: '*aplican términos y condiciones',
	EstaSuscripcionSeraRenovada:
		'*esta suscripción será automáticamente renovada cada año.',

	PlanGratis: 'Plan Gratis',
	PlanPremium: 'Plan Premium',
	PlanPremiumPlus: 'Plan Premium Plus',

	ArtistaIndividual: 'Artista individual',

	PorAño: 'por año',
	PorSiempre: 'por siempre',
	PorMes: 'por mes',
	PrecioPorDemanda: '*Precio por demanda',
	ContactoDatarte: 'a contacto@datarte.art',

	MentoriasGrupalesSemanales:
		'Mentorías grupales semanales de máximo 5 personas',
	AccesoACursosVirtualesMensualesDeAutogestion:
		'Acceso a cursos virtuales mensuales, de autogestión para adquirir más profesionalismo',
	AccesoACursosVirtualesMensualesDeAutogestionYFormacion:
		'Acceso a cursos virtuales mensuales, de autogestión y formación',

	ApoyoParaPresentarseALasConvocatorias:
		'Apoyo para presentarse a las convocatorias',
	ApoyoParaMaximizarLasPosibilidadesDeSerSeleccionadoEnLasConvocatorias:
		'Apoyo para maximizar las posibilidades de ser seleccionado en las convocatorias',
	ConexionesConAgentesYNuevosPublicos:
		'Conexiones con agentes y nuevos públicos para desarrollar la trayectoria artística',
	MentoriaIndividual: 'Mentoría individual',
	PosibilidadDePostularParaSerPublicadoNuevosPortafolios:
		'Posibilidad de postular para ser publicado su portafolio en Nuevos portafolios y en nuestras redes sociales',
	ConexionesDirectasConContactosDelEcosistemaDeLasArtesVisualesyOportunidades:
		'Conexiones directas con contactos del ecosistema de las artes visuales y oportunidades',
	Representacion: 'Representación',

	AccesoALaWebApp: 'Acceso a la web app',
	InventarioDeObrasIlimitadas: 'Inventario de obras ilimitadas',
	CreacionDePortafoliosIlimitados: 'Creación de portafolios ilimitados',
	AccesoAConvocatoriasPublicasYExclusivasDeDatarte:
		'Acceso a convocatorias públicas y a exclusivas de Datarte (No están en ningún otro lugar)',
	PostulacionesIlimitadasATodasLasConvocatorias:
		'Postulaciones ilimitadas a todas las convocatorias',
};
