// return user data from the local session storage
function getUser() {
	const userStr = localStorage.getItem('app_user');
	if (userStr) return JSON.parse(userStr);
	else return null;
}

// return token data from the local session storage
function getToken() {
	localStorage.getItem('token');
	return localStorage.getItem('token' || null);
}

//remove the token and user from the local session storage
function removeUserSession(navigate) {
	localStorage.clear();
	navigate('/iniciar-sesion');
	setTimeout(() => {
		window.location.reload();
	}, 500);
}

//set the token and user from the local storage
function setUserSession(
	app_user,
	token,
	role_user_name,
	organization_user_id,
	role_user_id,
	unknown_values
) {
	localStorage.setItem('app_user', JSON.stringify(app_user));
	localStorage.setItem('token', token);
	localStorage.setItem('role_user_name', JSON.stringify(role_user_name));
	localStorage.setItem(
		'organization_user_id',
		JSON.stringify(organization_user_id)
	);
	localStorage.setItem('role_user_id', JSON.stringify(role_user_id));
	localStorage.setItem('unknown_values', JSON.stringify(unknown_values));
}

function changeSession(role_user_name, organization_user_id, role_user_id) {
	localStorage.setItem('role_user_name', JSON.stringify(role_user_name));
	localStorage.setItem(
		'organization_user_id',
		JSON.stringify(organization_user_id)
	);
	localStorage.setItem('role_user_id', JSON.stringify(role_user_id));
}

//login app user
function app_user_login(values) {
	return fetch('/api/login/', {
		method: 'POST',
		cache: 'no-cache',
		headers: { content_type: 'application/json' },
		body: JSON.stringify(values),
	})
		.then((res) => res.json())
		.then((data) => {
			if (Object.keys(data).includes('error')) {
				//removeUserSession();
				alert('Lo siento, credenciales erroneas!');
			} else {
				setUserSession(
					data['app_user'],
					data['token'],
					data['role_user_name'],
					data['organization_user_id'],
					data['role_user_id'],
					data['unknown_values']
				);
			}
		});
}

//check Token
function check_token(app_user, token) {
	let values = {
		app_user: app_user,
		token: token,
	};
	return fetch('/api/check_token/', {
		method: 'POST',
		cache: 'no-cache',
		headers: { content_type: 'application/json' },
		body: JSON.stringify(values),
	})
		.then((res) => res.json())
		.then((data) => {
			if (Object.keys(data).includes('error')) {
				removeUserSession();
			} else {
				setUserSession(data.app_user, data.token);
			}
		});
}

//register a new user and organization
function app_user_register(values) {
	return fetch('/api/organization_user/register/', {
		method: 'POST',
		cache: 'no-cache',
		headers: { content_type: 'application/json' },
		body: JSON.stringify(values),
	})
		.then((res) => res.json())
		.then((data) => {
			if (data === 'User already exists') {
				throw new Error('User already exists');
			}
		});
}

function getDataToRegister(end_point) {
	return fetch(end_point, {
		method: 'GET',
		cache: 'no-cache',
		headers: { content_type: 'application/json' },
	}).then((res) => res.json());
}

export {
	getUser,
	getToken,
	removeUserSession,
	setUserSession,
	app_user_login,
	check_token,
	app_user_register,
	getDataToRegister,
	changeSession,
};
