//3rd party
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

//BL
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';
import {
	filterArtworkGalleryInitialValues,
	filterArtworkArtistInitialValues,
} from '../../../BusinessLogic/data/ArtworkSingleData';

//UI
import FilterArtwork from './FilterArtwork';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import ButtonChangeView from '../../../UI/molecules/Buttons/ButtonChangeView';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import BlankSpacesTemplate from '../../../UI/templates/BlankSpaces/BlankSpacesTemplate';
import artworksImage from '../../../UI/assets/images/BlankSpacesImages/artworksImg.jpg';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ArtworksGridView from '../../../UI/organisms/Artworks/ArtworksGridView';

export default function AllArtworks() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { artworks, status } = useSelector((state) => state.artworks);
	const { appUser } = useSelector((state) => state.appUser);

	const isGalleryUser = appUser.role_company === 'GALLERY';
	const filterInitialValues = () => {
		if (isGalleryUser) {
			return filterArtworkGalleryInitialValues;
		} else {
			return filterArtworkArtistInitialValues;
		}
	};

	const [changeView, setChangeView] = useState(false);
	const [valuesFilterArtworks, setValuesFilterArtworks] = useState(
		filterInitialValues()
	);
	const [artworksFiltered, setArtworksFiltered] = useState(artworks);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
	}, [dispatch, artworks]);

	useEffect(() => {
		const handleFilterArtworks = () => {
			if (artworks) {
				let dataFiltered = artworks;
				Object.keys(valuesFilterArtworks)?.forEach((field) => {
					if (valuesFilterArtworks[field] !== '') {
						if (field === 'artwork') {
							dataFiltered = dataFiltered.filter((item) =>
								item?.name
									.toLowerCase()
									.includes(valuesFilterArtworks[field].toLowerCase())
							);
						} else {
							dataFiltered = dataFiltered?.filter((item) => {
								if (field === 'date') {
									let date = new Date(item[field]);
									let year = date.getFullYear();
									return Number(year) === Number(valuesFilterArtworks[field]);
								} else if (typeof item[field] === 'number') {
									return item[field] === Number(valuesFilterArtworks[field]);
								} else {
									return item[field] === valuesFilterArtworks[field];
								}
							});
						}
					}
				});
				setArtworksFiltered(dataFiltered);
			}
		};

		handleFilterArtworks();
	}, [valuesFilterArtworks, artworks]);

	return (
		<div>
			{status === 'loading' ? (
				<ViewAllSkeleton
					numberOfImages={artworksFiltered?.length}
					designSkeletonTitle4={'invisible'}
					designSkeletonImages={
						'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
					}
				/>
			) : (
				<TemplateWithNavBar section={'Obras'}>
					{Array.isArray(artworks) && artworks.length > 0 ? (
						<>
							<SecondaryNavbar
								title={t('Obras')}
								textColor='text-yellow-50'
								color='bg-yellow-50'
								redirect='home'
								hidden='invisible'
								showchangeView={false}
								showFilter={false}
							>
								<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
									<ButtonChangeView
										changeView={changeView}
										setChangeView={setChangeView}
									/>
									<FilterArtwork
										valuesFilterArtworks={valuesFilterArtworks}
										setValuesFilterArtworks={setValuesFilterArtworks}
										valuesFilterInitialValues={filterInitialValues()}
									/>
								</div>
							</SecondaryNavbar>
							<div className='flex w-full flex-col items-center justify-between px-4 xs:mb-5 xs:h-12 xs:flex-row md:hidden'>
								<TextMainTitle
									title={t('Obras')}
									textColor='text-yellow-50'
									color='bg-yellow-50'
								/>
								<div className='my-3 flex w-full flex-row justify-between xs:my-0 xs:w-auto'>
									<ButtonChangeView
										changeView={changeView}
										setChangeView={setChangeView}
									/>
									<FilterArtwork
										valuesFilterArtworks={valuesFilterArtworks}
										setValuesFilterArtworks={setValuesFilterArtworks}
										valuesFilterInitialValues={filterInitialValues()}
									/>
								</div>
							</div>
							<ArtworksGridView
								artworksData={artworksFiltered ?? []}
								redirect='obras'
								changeView={changeView}
								emptyDataMessage={t('NoHayResultados')}
							/>
						</>
					) : (
						<>
							<SecondaryNavbar
								title={t('Obras')}
								textColor='text-yellow-50'
								color='bg-yellow-50'
								redirect='home'
								hidden='invisible'
								showchangeView={false}
								showFilter={false}
							/>
							<div className='flex w-full flex-col items-center pb-8 md:pb-0'>
								<TextMainTitle
									title={t('Obras')}
									textColor=' text-yellow-50 md:hidden'
									color={'bg-yellow-50 w-[100px] md:hidden'}
								/>
							</div>
							<BlankSpacesTemplate
								title={'OrdenaTuCreatividad'}
								subtitle={'CatalogaTusObras'}
								text={'TusObrasQuedanRegistradas'}
								image={artworksImage}
								link={'/obras/new'}
							/>
						</>
					)}
					<ButtonFabPrincipalUrl label={t('Crear')} url='/obras/new' />
				</TemplateWithNavBar>
			)}
		</div>
	);
}
