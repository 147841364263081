import { useTranslation } from 'react-i18next';
import { ReactComponent as StarPaymentIcon } from '../../assets/icons/StarPayment.svg';
import PaymentBenefit from './PaymentBenefit';
import { Link } from 'react-router-dom';

const PaymentCard = ({
	name,
	role,
	price,
	duration,
	benefits,
	best_choice,
	redirect,
}) => {
	const { t } = useTranslation();
	const premiumBenefits = benefits?.premium;
	const commonBenefits = benefits?.common;
	const bestChoiceBackground = best_choice ? 'bg-light_grey-75' : '';

	return (
		<article className='  rounded border border-gray-100 bg-white shadow-lg '>
			<div className={`px-5 py-8 ${bestChoiceBackground}`}>
				{best_choice && (
					<span className=' mb-4 mr-auto flex max-w-max items-center  gap-2 rounded  bg-yellow-50 p-2 text-xs text-white'>
						<StarPaymentIcon />
						{t('MejorOferta')}
					</span>
				)}
				<h2 className=' text-3xl font-bold text-black-50'>{t(name)}</h2>
				<h3 className='text-sm font-bold text-green-50'>{t(role)}</h3>
				<h3 className='mt-2 text-2xl font-bold text-black-50'>{t(price)}</h3>
				<h4 className='  mb-3 font-light text-black-50'>{t(duration)}</h4>
				{redirect ? (
					<Link to={redirect}>
						<button className='w-full max-w-[190px] rounded  bg-green-50 px-2 py-3 text-sm text-white shadow-sm lg:hover:opacity-60'>
							{t('Suscribirse')}
						</button>
					</Link>
				) : (
					<button className='w-full max-w-[190px] rounded  bg-green-50 px-2 py-3 text-sm text-white shadow-sm lg:hover:opacity-60'>
						{t('Suscribirse')}
					</button>
				)}
			</div>
			<div className='space-y-5 px-5 pb-5 pt-3  text-start text-sm font-extralight text-black-50'>
				<h4>{t('ParaArtistasQueDeseanOportunidades')}</h4>
				<h4 className=' font-normal'>{t('EstoIncluye')}</h4>
				<ul className=' space-y-3'>
					{premiumBenefits?.map((benefit, index) => (
						<PaymentBenefit key={index} benefit={benefit} isPremium={true} />
					))}
					{commonBenefits?.map((benefit, index) => (
						<PaymentBenefit key={index} benefit={benefit} />
					))}
				</ul>
				<div role='note'>
					<p className='mb-3 text-xs font-light text-black-50'>
						{t('AplicanTerminosCondiciones')}
					</p>
					<p className=' text-xs font-light text-black-50'>
						{t('EstaSuscripcionSeraRenovada')}
					</p>
				</div>
			</div>
		</article>
	);
};

export default PaymentCard;
