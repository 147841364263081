export const VIEWALL_FR = {
	Añadir: 'Add',
	Perfil: 'Profile',
	FILTROS: 'FILTERS',
	LIMPIAR: 'CLEAN',
	BuscarEnDatarte: 'Search in Datarte',
	Nombre: 'Name',
	Nombres: 'Names',
	Apellidos: 'Surnames',
	Rol: 'Role',
	País: 'Country',
	Teléfono: 'Phone',
	Celular: 'Cell phone',
	NohayConvocatoriasPorElMomento: 'There are no calls at the moment.',
	Convocatorias: 'Calls',
	RegresarAConvocatorias: 'Return to Calls',
	TodasLasPostulaciones: 'All applications',
	PostulacionesNuevas: 'New applications',
	PostulacionesAprobadas: 'Approved applications',
	PostulacionesRechazadas: 'Rejected applications',
	DescargarImágenes: 'Download Images',
	DescargarDatos: 'Download Data',
	NoHayPostulacionesPorElMomento: 'There are no applications at the moment.',
	Configuración: 'Setting',
	OrganizacionesVinculadas: 'Linked organizations',
	AdministrarPlan: 'Manage Plan',
	CerrarSesión: 'Sing Out',
	IdentificaciónUsuario: 'User identification',
	InformaciónUsuario: 'User information',
	Contraseña: 'Password',
	PáginaWeb: 'Web page',
	Dirección: 'Address',
	Compañía: 'Company',
	Moneda: 'Currency',
	VeaLasCuentasAdjuntas:
		'See the attached accounts on this site and what roles were assigned to them.',
	Cuentas: 'Accounts',
	VerCuenta: 'See account',
	EditarCuenta: 'Edit Account',
	RolEnLaCuenta: 'Account Role',
	PlanCuenta: 'Plan Account:',
	PlanAcceso: 'Access Plan:',
	Administrador: 'Administrator',
	AñadirRolesYPermisos: 'Add roles and permissions',
	RolesYPermisos: 'Roles and permissions',
	SeleccionaUnoOMásRoles:
		'Select one or more roles for the people you are inviting. To change roles, go to manage roles and permissions.',
	MiraQuiénesPuedenTrabajar:
		'See who can work on this site and what roles have been assigned to them.',
	RolesAsignados: 'Assigned roles',
	ParaInvitarAMúltiplesPersonas:
		'To invite multiple people, enter each email separated by a comma.',
	AdministradorCoPropietario: 'Administrator (Co-owner)',
	Editor: 'Editor',
	Lector: 'Reader',
	UsuarioTemporal: 'Temporary user',
	TieneAccesoCompletoParaAdministrar:
		'You have full access to manage, edit and publish artwork and more.',
	PuedeAdministrarCompletamenteLaInfo:
		'You can fully manage app information, but not other areas of your account.',
	PuedeCrearOResponderMensajes:
		'You can create or reply to messages or offers without editing anything in your account.',
	AsignaUnUsuarioTemporal:
		'Assign a temporary username and password to fully display the app information without editing anything in your account.',
	EnvíarAEsteContactoUnaInvitación:
		'Send this contact an email invitation to join my account.',
	Enviar: 'Send',
	Guardar: 'Save',
	Cancelar: 'Cancel',
	VERMÁS: 'See more',
	plantillas: 'Template',
	portafolioBásico: 'Basic portfolio',
	RegresarPlantillas: 'Back to Templates',
	OrdenaTuCreatividad: 'Order your creativity',
	CatalogaTusObras: 'Catalog your artworks',
	TusObrasQuedanRegistradas:
		'Your artworks are registered, archived, organized and digitized so that the creation of your portfolio can be done in the simplest way possible.',
	AhorraTiempo: 'Save time',
	CreaTusColecciones: 'Create your collections',
	crearTusPropiasColecciones:
		'At Datarte you can create your own collections grouping your artworks easily and quickly.',
	CreaTusPortafolios: 'Create your portfolios',
	CreaYDiseñaTuPortafolio:
		'Create and design your portfolio of selected artworks to easily access the postulations and have new opportunities for the diffusion of your artwork',
	ObténEstímulos: 'Get rewards',
	Oportunidades: 'Opportunities',
	AplicaAConvocatoriasFácilmente:
		'Apply to calls easily with your previously created portfolio in the app. Boost your career by applying to new opportunities.',
	OptimizaTuSelección: 'Optimize your selection',
	VisualizarYGestionarLasPostulaciones: 'View and manage applications',
	CreaTuConvocatoria:
		'Create your call and manage all applications easily in one place.',
	ParaCrearUnaConvocatoria: 'To create a call, ',
	HazClickAquí: 'click here.',
	Filtros: 'Filters',
	MásRecientesPrimero: 'Newest first',
	MásAntiguasPrimero: 'Oldest first',
	NombreConvocatoriaAZ: 'Call name (A-Z)',
	NombreConvocatoriaZA: 'Call name  (Z-A)',
	FiltrarPor: 'Filter by',
	Categoría: 'Category',
	Exposición: 'Exhibition',
	V2: 'V2',
	ResidenciaArtística: 'Artistic residency',
	RevisiónDePortafolios: 'Portfolios review',
	Pintura: 'Painting',
	Escultura: 'Sculpture',
	Dibujo: 'Drawing',
	Performance: 'Performance',
	Videoarte: 'Videoart',
	Otra: 'Other',
	NombreDeConvocatoria: 'Call name',
	Organización: 'Organization',
	FechaCierre: 'Close date',
	LimpiarFiltro: 'Clean filters',
	Aplicar: 'Apply',
	ArtistaAZ: 'Artist (A-Z)',
	ArtistaZA: 'Artist (Z-A)',
	EstadoPostulación: 'Status',
	Nueva: 'New',
	Aprobada: 'Approved',
	Rechazada: 'Rejected',
	Leída: 'Read',
	Guardada: 'Saved',
	FechaAplicación: 'Postulation date',
	NombreArtista: 'Artist name',
	LoQueEstaPasando: "What's happening",
	RecursosDatarte: 'Datarte Resources',
	AperturaDeConvocatoria: 'Opening of call',
	FinalizaciónDeConvocatoria: 'End of call',
	ResultadosDeConvocatoria: 'Call results',
	EventosImportantes: 'Important events',
	leerMás: 'Read more',
};
