export const NAVBAR_FR = {
	Catalogación: 'Catalogage',
	Difusión: 'Diffusion',
	Convocatoria: 'Appeler',
	Perfil: 'Profil',
	Artistas: 'Artistes',
	Obras: 'Usine',
	Documentos: 'Documents',
	Lugares: 'Emplacements',
	Contactos: 'Contacts',
	Exposiciones: 'Exposition',
	Colecciones: 'Collections',
	Portafolios: 'Portefeuille',
	ConvocatoriasAbiertas: ' Appels',
	MisPostulaciones: 'My applications',
	Postulaciones: 'Applications',
	Catalogo: 'Catalogue',
	TuPlanEstaActivo: 'Your plan is active',
	TuPlanSeHaActivadoExitosamente:
		'Your plan has been successfully activated. You can now start using the Datarte app and apply to the calls for applications.',
};
