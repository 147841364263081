import React from 'react';
import PropTypes from 'prop-types';

//UI
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';

export const ModalPostulation = ({
	actionClose,
	modalTitle,
	modalText,
	buttonLabel,
	modalClassName,
}) => {
	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black-50 bg-opacity-80 '>
			<div className='relative mx-auto my-6 w-full p-6 sm:w-1/2 md:p-0 lg:w-1/3'>
				<div
					className={`relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg ${modalClassName}`}
				>
					<div className='flex items-start justify-center px-5 pb-2.5 pt-5 text-center'>
						<TextTitleSub title={modalTitle} style={{ fontWeight: 600 }} />
					</div>
					<div className='relative flex flex-col items-center justify-center px-2 pb-2 text-center'>
						<TextContentSecond text={modalText} design='mb-2' />
					</div>
					<div className='flex w-full justify-center py-4'>
						<ButtonFab label={buttonLabel} action={actionClose} />
					</div>
				</div>
			</div>
		</div>
	);
};
ModalPostulation.propTypes = {
	/** status of the function that closes the menu eh implements another action*/
	action: PropTypes.func,
	/* string with the modal title display */
	modalTitle: PropTypes.string,
	/* string that asking to confirm delete the entity */
	modalText: PropTypes.string,
	/* string that identifies the button text */
	buttonLabel: PropTypes.string,
	/* boolean that add extra padding and reduce width of the modal */
	wrapper: PropTypes.string,
};
