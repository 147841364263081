//3rd party
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//BL
import { dateShortFormat } from '../../../BusinessLogic/helpers/dateFormats';
import {
	useGetAllCallsByRole,
	usePostCreateCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';
import { cleanEditingCall } from '../../../BusinessLogic/redux/reducers/call';

//UI
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import SecondaryNavbar from '../../../UI/organisms/Navbar/NavbarSecondary';
import SectionAllCall from '../../../UI/organisms/Section/SectionAllCall';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import Cross from '../../../UI/assets/icons/CrossWhite.svg';
import FilterCall from '../../../UI/molecules/Filters/FilterCall';
import BlankSpacesTemplate from '../../../UI/templates/BlankSpaces/BlankSpacesTemplate';
import callUserImage from '../../../UI/assets/images/BlankSpacesImages/callsUser.jpg';
import callOrganizationImage from '../../../UI/assets/images/BlankSpacesImages/CallsOrganization.jpg';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * @description manages the AllCall page.
 * @returns
 */
const AllCall = () => {
	const { appUser } = useSelector((state) => state.appUser);
	const [changeView, setChangeView] = useState(false);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const postCreateCallMutation = usePostCreateCall();

	const isArtistUser = appUser?.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser?.role_company === 'ARTIST_CATALOGATION';
	const isOrganizationUser = appUser?.role_company === 'ORGANIZATION';
	const isGalleryUser = appUser?.role_company === 'GALLERY';

	const { calls } = useGetAllCallsByRole({
		organization_user_id: appUser.organization_user_id,
		role_company: appUser.role_company,
	});

	const [callsFiltered, setCallsFiltered] = useState(calls);
	const initialFilterParameters = {
		sorting: '',
		category: '',
		technique: '',
		country: '',
		city: '',
		name: '',
		organization: '',
		date: '',
	};
	const [filterParameters, setFilterParameters] = useState(
		initialFilterParameters
	);

	const isCallsAnArray = Array.isArray(calls);

	const sortCallsByRecentDate = (calls) => {
		if (isCallsAnArray) {
			return [...calls].sort((a, b) => {
				const dateA = new Date(a?.updated_at ?? '');
				const dateB = new Date(b?.updated_at ?? '');
				return dateB - dateA;
			});
		}
	};

	const sortCallsByRecentDateAndStatus = (calls) => {
		return isCallsAnArray
			? [
					...sortCallsByRecentDate(
						calls?.filter((call) => call.status !== 'CLOSED')
					),
					...sortCallsByRecentDate(
						calls?.filter((call) => call.status === 'CLOSED')
					),
			  ]
			: [];
	};

	const sortCalls = (parameter, callsToSort) => {
		switch (parameter) {
			case 'date_ascending':
				return sortCallsByRecentDate(callsToSort);

			case 'date_descending':
				return [...callsToSort].sort((a, b) => {
					const dateA = new Date(a?.updated_at ?? '');
					const dateB = new Date(b?.updated_at ?? '');
					return dateA - dateB;
				});
			case 'name_ascending':
				return [...callsToSort].sort((a, b) => {
					const nameA = a?.name ?? '';
					const nameB = b?.name ?? '';
					return nameA.localeCompare(nameB);
				});
			case 'name_descending':
				return [...callsToSort].sort((a, b) => {
					const nameA = a?.name ?? '';
					const nameB = b?.name ?? '';
					return nameB.localeCompare(nameA);
				});
			default:
				return callsToSort;
		}
	};

	useEffect(() => {
		dispatch(cleanEditingCall());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let sortedAndFilteredCalls = sortCallsByRecentDateAndStatus(calls);

		if (filterParameters.category) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter((call) =>
				(call.category ?? '').includes(filterParameters.category)
			);
		}

		if (filterParameters.technique) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter((call) =>
				(call.technique ?? '').includes(filterParameters.technique)
			);
		}

		if (filterParameters.country) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter(
				(call) => String(call.country_id) === filterParameters.country
			);
		}

		if (filterParameters.city) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter(
				(call) =>
					call.city?.localeCompare(filterParameters.city, 'en', {
						sensitivity: 'base',
					}) === 0
			);
		}

		if (filterParameters.name) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter((call) =>
				call.name
					?.toLocaleLowerCase()
					.includes(filterParameters.name.toLocaleLowerCase())
			);
		}

		if (filterParameters.organization) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter((call) =>
				call.organization_name
					?.toLocaleLowerCase()
					.includes(filterParameters.organization.toLocaleLowerCase())
			);
		}

		if (filterParameters.date) {
			sortedAndFilteredCalls = sortedAndFilteredCalls.filter(
				(call) =>
					dateShortFormat(`${filterParameters.date} 00:00:00`) ===
					dateShortFormat(call.closes_at)
			);
		}

		if (filterParameters.sorting) {
			sortedAndFilteredCalls = sortCalls(
				filterParameters.sorting,
				sortedAndFilteredCalls
			);
		}

		setCallsFiltered(sortedAndFilteredCalls);
		//eslint-disable-next-line
	}, [filterParameters, calls]);

	const createCall = async () => {
		const res = await postCreateCallMutation.mutateAsync();

		if (res?.default_slug) {
			navigate(`/convocatorias/${res.default_slug}`);
		} else {
			navigate('/convocatorias');
		}
	};

	return (
		<TemplateWithNavBar>
			<SecondaryNavbar
				title={t('Convocatorias')}
				textColor='text-purple-50'
				color='bg-purple-50'
				redirect='home'
				hidden='invisible'
				changeView={changeView}
				setChangeView={setChangeView}
				showFilter={false}
				showchangeView={false}
			>
				<FilterCall
					initialFilterParameters={initialFilterParameters}
					filterParameters={filterParameters}
					setFilterParameters={setFilterParameters}
				/>
			</SecondaryNavbar>
			<div className='relative mb-5 flex h-12 w-full items-center justify-center md:hidden'>
				<TextMainTitle
					title={t('Convocatorias')}
					textColor='text-purple-50'
					color='bg-purple-50'
				/>
				<span className='absolute right-0'>
					<FilterCall
						initialFilterParameters={initialFilterParameters}
						filterParameters={filterParameters}
						setFilterParameters={setFilterParameters}
					/>
				</span>
			</div>
			<SectionAllCall callsData={callsFiltered} redirect='convocatorias' />

			{calls &&
				(isArtistUser || isCatalogueArtistUser || isGalleryUser) &&
				calls[0] === undefined && (
					<div className='-mt-10'>
						<BlankSpacesTemplate
							title={'ObténEstímulos'}
							subtitle={'Oportunidades'}
							text={'AplicaAConvocatoriasFácilmente'}
							paragraph={'NohayConvocatoriasPorElMomento'}
							image={callUserImage}
							message={calls.message}
							isThereTextWithLink={false}
						/>
					</div>
				)}
			{calls && isOrganizationUser && calls[0] === undefined && (
				<div className='-mt-10'>
					<BlankSpacesTemplate
						title={'OptimizaTuSelección'}
						subtitle={'VisualizarYGestionarLasPostulaciones'}
						text={'CreaTuConvocatoria'}
						paragraphToTextWithAction={'ParaCrearUnaConvocatoria'}
						spanText={'HazClickAquí'}
						image={callOrganizationImage}
						isThereTextWithLink={false}
						isThereTextWithAction={true}
						action={() => createCall()}
						designContainerText='w-4/5'
					/>
				</div>
			)}
			{isOrganizationUser && (
				<ButtonFabPrincipalUrl
					label={t('Crear')}
					icon={Cross}
					url='/convocatorias'
					action={() => createCall()}
				/>
			)}
		</TemplateWithNavBar>
	);
};

export default AllCall;

AllCall.propTypes = {};

AllCall.defaultProps = {};
