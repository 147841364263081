import * as Yup from 'yup';

const currentYear = new Date().getFullYear();

const artworkObjectTypes = [
	{ label: 'Bi-dimensional', value: 'BI-DIMENSIONAL' },
	{ label: 'Tri-dimensional', value: 'TRI-DIMENSIONAL' },
	{ label: 'Video', value: 'VIDEO' },
	{ label: 'Performance', value: 'PERFORMANCE' },
	{ label: 'Animación', value: 'ANIMATION' },
	{ label: 'Otro', value: 'OTRO' },
];

const artworkResolutions = [
	{ label: '7680p (8K): 7680 x 4320', value: '7680p (8K): 7680 x 4320' },
	{ label: '2160p (4K): 3840 x 2160', value: '2160p (4K): 3840 x 2160' },
	{ label: '1440p: 25060 x 1440', value: '1440p: 25060 x 1440' },
	{ label: '1080p: 1920 x 1080', value: '1080p: 1920 x 1080' },
	{ label: '720p: 1280 x 720', value: '720p: 1280 x 720' },
	{ label: '480p : 854 x 480', value: '480p : 854 x 480' },
	{ label: '360p: 640 x 360', value: '360p: 640 x 360' },
	{ label: '240p: 426 x 240', value: '240p: 426 x 240' },
];

const artworkAspects = [
	{ label: '1:1', value: '1:1' },
	{ label: '3:2', value: '3:2' },
	{ label: '4:3', value: '4:3' },
	{ label: '16:9', value: '16:9' },
	{ label: '21:9', value: '21:9' },
];

//Single artwork tabs information
// Catalog artist
const catalogueArtistArtworkSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{ label: 'Código', value: '', field: 'code' },
					{ label: 'OtroCodigo', value: '0001', field: 'code_other' },
					{
						label: 'Tipo',
						value: 'BiDimensional',
						field: 'object_type',
						type: 'dropdown',
						options: [
							{ label: 'Bi-dimensional', value: 'BI-DIMENSIONAL' },
							{ label: 'Tri-dimensional', value: 'TRI-DIMENSIONAL' },
							{ label: 'Otro', value: 'OTRO' },
						],
					},
					{ label: 'Fecha', value: '23/04/01', field: 'date', type: 'date' },
					{
						label: 'PaisDeOrigen',
						value: '12',
						field: 'origin_country_id',
						type: 'dropdown',
						options: [
							{ value: 47, label: 'Colombia' },
							{ value: 48, label: 'Brazil' },
						],
					},
					{ label: 'Diferencias', value: '', field: 'difference' },
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},
			{
				title: 'Autor',
				type: 'labels',
				information: [
					{
						label: 'Artista',
						value: '',
						field: 'artist_id',
						type: 'dropdown',
						options: [],
						hideDefaultOption: true,
					},
					/* {
						label: 'Colecciones',
						value: '',
						field: 'collections',
						type: 'collections',
						options: [{ value: 4, label: 'Colección 1' }],
					}, */
				],
			},
			{
				title: 'Observaciones',
				type: 'text',
				field: 'observations',
				information: '',
			},
		],
	},
	{
		tab: 'FichaTecnica',
		type: 'Information',
		content: [
			{
				title: 'DimensionesObjeto',
				type: 'labels',
				field: 'dimensions',
				information: [
					{
						label: 'UnidadDeMedida',
						value: '',
						field: 'measure_id',
						type: 'dropdown',
						options: [{ label: 'cm-kg', value: 1 }],
					},
					{
						label: 'Altura',
						value: '',
						field: 'dimensions_height',
						type: 'number',
					},
					{
						label: 'Largo',
						value: '',
						field: 'dimensions_length',
						type: 'number',
					},
					{
						label: 'Ancho',
						value: '',
						field: 'dimensions_width',
						type: 'number',
					},
					{
						label: 'Peso',
						value: '',
						field: 'dimensions_weight',
						type: 'number',
					},
				],
			},
			{
				title: 'DimensionesBase',
				type: 'labels',
				field: 'dimension_base',
				information: [
					{
						label: 'Altura',
						value: '',
						field: 'dimensions_base_height',
						type: 'number',
					},
					{
						label: 'Largo',
						value: '',
						field: 'dimensions_base_length',
						type: 'number',
					},
					{
						label: 'Ancho',
						value: '',
						field: 'dimensions_base_width',
						type: 'number',
					},
					{
						label: 'Peso',
						value: '',
						field: 'dimensions_base_weight',
						type: 'number',
					},
				],
			},
			{
				title: 'Componentes',
				type: 'labels',
				field: 'components',
				information: [
					{
						label: 'TieneSubcomponentes',
						value: '',
						field: 'components',
						type: 'dropdown',
						isDropdownBoolean: true,
						options: [
							{ label: 'Si', value: true },
							{ label: 'No', value: false },
						],
					},
					{
						label: 'NumeroDeComponentes',
						value: '',
						field: 'components_number',
						type: 'number',
					},
					{ label: 'Detalles', value: '', field: 'components_detail' },
				],
			},
			{
				title: 'TecnicaYMateriales',
				type: 'labels',
				field: 'techniques',
				information: [
					{ label: 'Técnica', value: '', field: 'technique' },
					{ label: 'Medida', value: '', field: 'medium' },
					{ label: 'Soporte', value: '', field: 'holder' },
					{ label: 'Materiales', value: '', field: 'materials' },
				],
			},
			{
				title: 'EstadoDeConservacion',
				type: 'text',
				field: 'preservation_status',
				information: '',
			},
		],
	},
	{
		tab: 'Detalles',
		type: 'Information',
		content: [
			{
				title: 'Propietario',
				type: 'labels',
				field: 'property',
				information: [
					{
						label: 'EstadoPropietario',
						value: '',
						field: 'owner_status',
						type: 'dropdown',
						options: [
							{ label: 'Conservado', value: 'CONSERVADO' },
							{ label: 'Vendido', value: 'VENDIDO' },
							{ label: 'Donado', value: 'DONADO' },
							{ label: 'Disponible', value: 'DISPONIBLE' },
							{ label: 'Desconocido', value: 'DESCONOCIDO' },
						],
					},
					{
						label: 'Propietario',
						value: '',
						field: 'owner_id',
						type: 'dropdown',
						options: [{ value: 1, label: 'Desconocido' }],
					},
				],
			},
			{
				title: 'Ubicación',
				type: 'location',
				field: 'location',
				information: [
					{ label: 'Lugar', value: '', field: 'location_general_name' },
					{ label: 'Sala', value: '', field: 'location_room_name' },
					{ label: 'Ubicación', value: '', field: 'location_name' },
				],
			},
			{
				title: 'Avalúo',
				type: 'labels',
				field: 'val',
				information: [
					{ label: 'Precio', value: '', field: 'price', type: 'number' },
					{
						label: 'Moneda',
						value: '',
						field: 'currency_id',
						type: 'dropdown',
						options: [
							{ label: 'COP', value: 4 },
							{ label: 'USD', value: 5 },
						],
					},
				],
			},
			{
				title: 'DigitalizacionDeLaObra',
				type: 'labels',
				field: 'responsable',
				information: [
					{
						label: 'FechaDeElaboracion',
						value: '',
						field: 'created_at',
						type: 'date',
					},
					{
						label: 'UltimaActualizacion',
						value: '',
						field: 'updated_at',
						type: 'date',
					},
				],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const catalogueArtistSingleArtworkButtons = [
	'Identificación',
	'FichaTecnica',
	'Detalles',
	'Imágenes',
];
//Single artwork tabs information
// artist

const artworkSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{
						label: 'Tipo',
						value: 'BiDimensional',
						field: 'object_type',
						type: 'dropdown',
						options: artworkObjectTypes,
					},
					{ label: 'Año', value: '2023', field: 'date', type: 'number' },
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},

			{
				title: 'DimensionesObjeto',
				type: 'labels',
				field: 'dimensions',
				information: [
					{
						label: 'UnidadDeMedida',
						value: '',
						field: 'measure_id',
						type: 'dropdown',
						options: [{ label: 'cm-kg', value: 1 }],
					},
					{
						label: 'Altura',
						value: '',
						field: 'dimensions_height',
						type: 'number',
					},
					{
						label: 'Largo',
						value: '',
						field: 'dimensions_length',
						type: 'number',
					},
					{
						label: 'Ancho',
						value: '',
						field: 'dimensions_width',
						type: 'number',
					},
					{
						label: 'Peso',
						value: '',
						field: 'dimensions_weight',
						type: 'number',
					},
				],
			},
			{
				title: 'TecnicaYMateriales',
				type: 'labels',
				field: 'techniques',
				information: [
					{ label: 'Técnica', value: '', field: 'technique' },
					{ label: 'Materiales', value: '', field: 'materials' },
				],
			},
			{
				title: 'Avalúo',
				type: 'labels',
				field: 'val',
				information: [
					{ label: 'Precio', value: '', field: 'price', type: 'number' },
					{
						label: 'Moneda',
						value: '',
						field: 'currency_id',
						type: 'dropdown',
						options: [
							{ label: 'COP', value: 4 },
							{ label: 'USD', value: 5 },
						],
					},
				],
			},
			{
				title: 'DigitalizacionDeLaObra',
				type: 'labels',
				field: 'responsable',
				information: [
					{
						label: 'FechaDeElaboracion',
						value: '',
						field: 'created_at',
						type: 'date',
					},
					{
						label: 'UltimaActualizacion',
						value: '',
						field: 'updated_at',
						type: 'date',
					},
				],
			},
			{
				title: 'Autor',
				type: 'labels',
				information: [
					{
						label: 'Artista',
						value: '',
						field: 'artist_id',
						type: 'dropdown',
						options: [],
						hideDefaultOption: true,
					},
				],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const artworkSingleTabsArtist = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{
						label: 'Tipo',
						value: 'BiDimensional',
						field: 'object_type',
						type: 'dropdown',
						options: artworkObjectTypes,
					},
					{ label: 'Año', value: '2023', field: 'date', type: 'number' },
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},

			{
				title: 'DimensionesObjeto',
				type: 'labels',
				field: 'dimensions',
				information: [
					{
						label: 'UnidadDeMedida',
						value: '',
						field: 'measure_id',
						type: 'dropdown',
						options: [{ label: 'cm-kg', value: 1 }],
					},
					{
						label: 'Altura',
						value: '',
						field: 'dimensions_height',
						type: 'number',
					},
					{
						label: 'Largo',
						value: '',
						field: 'dimensions_length',
						type: 'number',
					},
					{
						label: 'Ancho',
						value: '',
						field: 'dimensions_width',
						type: 'number',
					},
					{
						label: 'Peso',
						value: '',
						field: 'dimensions_weight',
						type: 'number',
					},
				],
			},
			{
				title: 'TecnicaYMateriales',
				type: 'labels',
				field: 'techniques',
				information: [
					{ label: 'Técnica', value: '', field: 'technique' },
					{ label: 'Materiales', value: '', field: 'materials' },
				],
			},
			{
				title: 'Avalúo',
				type: 'labels',
				field: 'val',
				information: [
					{ label: 'Precio', value: '', field: 'price', type: 'number' },
					{
						label: 'Moneda',
						value: '',
						field: 'currency_id',
						type: 'dropdown',
						options: [
							{ label: 'COP', value: 4 },
							{ label: 'USD', value: 5 },
						],
					},
				],
			},
			{
				title: 'DigitalizacionDeLaObra',
				type: 'labels',
				field: 'responsable',
				information: [
					{
						label: 'FechaDeElaboracion',
						value: '',
						field: 'created_at',
						type: 'date',
					},
					{
						label: 'UltimaActualizacion',
						value: '',
						field: 'updated_at',
						type: 'date',
					},
				],
			},
		],
	},
	{
		tab: 'Imágenes',
		type: 'Images',
		content: [
			{
				title: 'Imágenes',
				type: 'images',
				field: 'images',
				information: [],
			},
		],
	},
];

const singleArtworkButtons = ['Identificación', 'Imágenes'];

const artworkVideoSingleTabs = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{
						label: 'Tipo',
						value: 'Video',
						field: 'object_type',
						type: 'dropdown',
						options: artworkObjectTypes,
					},
					{
						label: 'URLVideo',
						value: '',
						field: 'video_url',
						type: 'text',
					},
					{
						label: 'Duración',
						value: '',
						field: 'duration',
						type: 'number',
					},
					{ label: 'Año', value: '2023', field: 'date', type: 'number' },
					{
						label: 'PaisDeOrigen',
						value: '12',
						field: 'origin_country_id',
						type: 'dropdown',
						options: [
							{ value: 47, label: 'Colombia' },
							{ value: 48, label: 'Brazil' },
						],
					},
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},
			{
				title: 'AutorVideo',
				type: 'labels',
				field: 'autor',
				information: [
					{
						label: 'AutorVideo',
						value: '',
						field: 'holder',
						type: 'text',
					},
				],
			},
			{
				title: 'Dimensiones',
				type: 'labels',
				field: 'dimensions',
				information: [
					{
						label: 'Resolution',
						value: '',
						field: 'resolution',
						type: 'dropdown',
						options: artworkResolutions,
					},
					{
						label: 'Aspect',
						value: '',
						field: 'aspect_ratio',
						type: 'dropdown',
						options: artworkAspects,
					},
					{
						label: 'NúmeroDeSerie',
						value: '',
						field: 'serial_number',
						type: 'text',
					},
				],
			},
			{
				title: 'TecnicaYMateriales',
				type: 'labels',
				field: 'techniques',
				information: [
					{ label: 'Técnica', value: '', field: 'technique' },
					{ label: 'Materiales', value: '', field: 'materials' },
				],
			},
			{
				title: 'Avalúo',
				type: 'labels',
				field: 'val',
				information: [
					{ label: 'Precio', value: '', field: 'price', type: 'number' },
					{
						label: 'Moneda',
						value: '',
						field: 'currency_id',
						type: 'dropdown',
						options: [
							{ label: 'COP', value: 4 },
							{ label: 'USD', value: 5 },
						],
					},
				],
			},
			{
				title: 'DigitalizacionDeLaObra',
				type: 'labels',
				field: 'responsable',
				information: [
					{
						label: 'FechaDeElaboracion',
						value: '',
						field: 'created_at',
						type: 'date',
					},
					{
						label: 'UltimaActualizacion',
						value: '',
						field: 'updated_at',
						type: 'date',
					},
				],
			},
			{
				title: 'Autor',
				type: 'labels',
				information: [
					{
						label: 'Artista',
						value: '',
						field: 'artist_id',
						type: 'dropdown',
						options: [],
						hideDefaultOption: true,
					},
				],
			},
		],
	},
];

const artworkVideoSingleTabsArtist = [
	{
		tab: 'Identificación',
		type: 'Information',
		content: [
			{
				title: 'Identificación',
				type: 'cityCountry',
				field: 'identification',
				information: [
					{ label: 'Nombre', value: '', field: 'name' },
					{
						label: 'Tipo',
						value: 'Video',
						field: 'object_type',
						type: 'dropdown',
						options: artworkObjectTypes,
					},
					{
						label: 'URLVideo',
						value: '',
						field: 'video_url',
						type: 'text',
					},
					{
						label: 'Duración',
						value: '',
						field: 'duration',
						type: 'number',
					},
					{ label: 'Año', value: '2023', field: 'date', type: 'number' },
					{
						label: 'PaisDeOrigen',
						value: '12',
						field: 'origin_country_id',
						type: 'dropdown',
						options: [
							{ value: 47, label: 'Colombia' },
							{ value: 48, label: 'Brazil' },
						],
					},
				],
			},
			{
				title: 'Descripción',
				type: 'text',
				field: 'description',
				information: '',
			},
			{
				title: 'AutorVideo',
				type: 'labels',
				field: 'autor',
				information: [
					{
						label: 'AutorVideo',
						value: '',
						field: 'holder',
						type: 'text',
					},
				],
			},
			{
				title: 'Dimensiones',
				type: 'labels',
				field: 'dimensions',
				information: [
					{
						label: 'Resolution',
						value: '',
						field: 'resolution',
						type: 'dropdown',
						options: artworkResolutions,
					},
					{
						label: 'Aspect',
						value: '',
						field: 'aspect_ratio',
						type: 'dropdown',
						options: artworkAspects,
					},
					{
						label: 'NúmeroDeSerie',
						value: '',
						field: 'serial_number',
						type: 'text',
					},
				],
			},
			{
				title: 'TecnicaYMateriales',
				type: 'labels',
				field: 'techniques',
				information: [
					{ label: 'Técnica', value: '', field: 'technique' },
					{ label: 'Materiales', value: '', field: 'materials' },
				],
			},
			{
				title: 'Avalúo',
				type: 'labels',
				field: 'val',
				information: [
					{ label: 'Precio', value: '', field: 'price', type: 'number' },
					{
						label: 'Moneda',
						value: '',
						field: 'currency_id',
						type: 'dropdown',
						options: [
							{ label: 'COP', value: 4 },
							{ label: 'USD', value: 5 },
						],
					},
				],
			},
			{
				title: 'DigitalizacionDeLaObra',
				type: 'labels',
				field: 'responsable',
				information: [
					{
						label: 'FechaDeElaboracion',
						value: '',
						field: 'created_at',
						type: 'date',
					},
					{
						label: 'UltimaActualizacion',
						value: '',
						field: 'updated_at',
						type: 'date',
					},
				],
			},
		],
	},
];

const singleArtworkVideoButtons = ['Identificación'];

const artworkValidationSchemaArtistSimple = [
	[
		{
			name: Yup.string('NombreDebeSerTexto').required('NombreEsNecesario'),
			object_type: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			date: Yup.number()
				.min(1900, 'AñoInvalido')
				.max(currentYear, 'AñoInvalido')
				.nullable(),
		},

		{
			description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},

		{
			dimensions_height: Yup.number('DimensionDebeSerPositiva')
				.required('DimensionEsNecesaria')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_length: Yup.number('DimensionDebeSerPositiva')
				.required('DimensionEsNecesaria')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_width: Yup.number('DimensionDebeSerPositiva')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_weight: Yup.number('DimensionDebeSerPositiva')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
		},

		{
			technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},

		{
			price: Yup.number('AvalúoPositivo')
				.required('AvalúoNecesario')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			valuation_currency: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
		},
		{
			created_at: Yup.date('FechaValida').nullable(),
			updated_at: Yup.date('FechaValida').nullable(),
		},
	],
	[],
];

const artworkVideoValidationSchema = [
	[
		{
			name: Yup.string('NombreDebeSerTexto').required('NombreEsNecesario'),
			object_type: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			video_url: Yup.string('UnURLEsNecesario')
				.url('UnURLEsNecesario')
				.required('UnURLEsNecesario'),
			duration: Yup.number().nullable(),
			date: Yup.number()
				.min(1900, 'AñoInvalido')
				.max(currentYear, 'AñoInvalido')
				.nullable(),
			origin_country_id: Yup.string().required('PaísEsNecesario'),
		},

		{
			description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			holder: Yup.string('').nullable(),
		},

		{
			resolution: Yup.string().nullable(),
			aspect_ratio: Yup.string().nullable(),
			serial_number: Yup.string().nullable(),
		},

		{
			technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},

		{
			price: Yup.number('AvalúoPositivo')
				.required('AvalúoNecesario')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			valuation_currency: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
		},
		{
			created_at: Yup.date('FechaValida').nullable(),
			updated_at: Yup.date('FechaValida').nullable(),
		},
	],
];

const artworkValidationSchema = [
	//this needs to have the same order than the buttons
	[
		//and the same order than the appearance of the content sections
		//far from elegant
		{
			name: Yup.string('NombreDebeSerTexto').required('NombreEsNecesario'),
			code: Yup.string().min(2, 'RequiereDosCaracteres'),
			code_other: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			object_type: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			date: Yup.date().nullable(),
			//origin_country_id:Yup.string().min(2, 'Se requieren mínimo 2 caracteres.'),
			difference: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},
		{
			description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
		{
			artist_name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			collection_name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},
		{
			observations: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
		},
	],
	[
		{
			dimensions_height: Yup.number('DimensionDebeSerPositiva')
				.required('DimensionEsNecesaria')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_length: Yup.number('DimensionDebeSerPositiva')
				.required('DimensionEsNecesaria')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_width: Yup.number('DimensionDebeSerPositiva')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_weight: Yup.number('DimensionDebeSerPositiva')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
		},
		{
			dimensions_base_height: Yup.number()
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_base_length: Yup.number()
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_base_width: Yup.number()
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			dimensions_base_weight: Yup.number()
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
		},
		{
			components: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			components_number: Yup.number().min(0, 'NumeroNoSerMenorCero').nullable(),
			components_detail: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
		},
		{
			technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			medium: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			holder: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},
		{
			preservation_status: Yup.string()
				.max(1000, 'NumeroMáximoCaracteres')
				.nullable(),
		},
	],
	[
		{
			owner_status: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
		},
		{
			location_name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			location_room_name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
			location_general_name: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
		},
		{
			price: Yup.number('AvalúoPositivo')
				.required('AvalúoNecesario')
				.min(0, 'NumeroNoSerMenorCero')
				.nullable(),
			valuation_currency: Yup.string()
				.min(2, 'RequiereDosCaracteres')
				.nullable(),
		},
		{
			created_by_name: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
			created_at: Yup.date('FechaValida').nullable(),
			updated_at: Yup.date('FechaValida').nullable(),
		},
	],
	[],
	[],
];

const artworkFormValidationSchema = Yup.object({
	name: Yup.string().required('NombreEsNecesario'),
	code: Yup.string().nullable(),
	date: Yup.date().nullable(),
	description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
	observations: Yup.string()
		.max(2000, 'NumeroMáximoCaracteresDosMil')
		.nullable(),
	preservation_status: Yup.string()
		.max(2000, 'NumeroMáximoCaracteresDosMil')
		.nullable(),
	dimensions_height: Yup.number('DimensionDebeSerPositiva')
		.required('DimensionEsNecesaria')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_length: Yup.number('DimensionDebeSerPositiva')
		.required('DimensionEsNecesaria')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_width: Yup.number('DimensionDebeSerPositiva')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_weight: Yup.number('DimensionDebeSerPositiva')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	holder: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	price: Yup.number('DebeSerUnNúmero')
		.typeError('AvalúoPositivo')
		.required('AvalúoNecesario')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	components_number: Yup.number().min(0, 'NumeroNoSerMenorCero').nullable(),
	components_detail: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	origin_country_id: Yup.string().required('PaísEsNecesario'),
	artist_id: Yup.string().required('DebeSeleccionarUnaOpción'),
});

const artworkFormValidationSchemaArtistSimple = Yup.object({
	name: Yup.string().required('NombreEsNecesario'),
	object_type: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	date: Yup.number()
		.min(1900, 'AñoInvalido')
		.max(currentYear, 'AñoInvalido')
		.nullable(),

	description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),

	dimensions_height: Yup.number('DimensionDebeSerPositiva')
		.required('DimensionEsNecesaria')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_length: Yup.number('DimensionDebeSerPositiva')
		.required('DimensionEsNecesaria')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_width: Yup.number('DimensionDebeSerPositiva')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	dimensions_weight: Yup.number('DimensionDebeSerPositiva')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	price: Yup.number('DebeSerUnNúmero')
		.typeError('AvalúoPositivo')
		.required('AvalúoNecesario')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	artist_id: Yup.string().required('DebeSeleccionarUnaOpción'),
});

const artworkVideoFormValidationSchema = Yup.object({
	name: Yup.string().required('NombreEsNecesario'),
	object_type: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	date: Yup.number()
		.min(1900, 'AñoInvalido')
		.max(currentYear, 'AñoInvalido')
		.nullable(),

	description: Yup.string().max(1000, 'NumeroMáximoCaracteres').nullable(),
	technique: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	materials: Yup.string().min(2, 'RequiereDosCaracteres').nullable(),
	price: Yup.number('DebeSerUnNúmero')
		.typeError('AvalúoPositivo')
		.required('AvalúoNecesario')
		.min(0, 'NumeroNoSerMenorCero')
		.nullable(),
	origin_country_id: Yup.string().required('PaísEsNecesario'),
	video_url: Yup.string('UnURLEsNecesario')
		.url('UnURLEsNecesario')
		.required('UnURLEsNecesario'),
	artist_id: Yup.string().required('DebeSeleccionarUnaOpción'),
});

const artworkInitialValues = {
	id: '',
	code: '',
	code_other: '',
	collection_id: 1,
	main_picture_id: 1,
	name: '',
	object_type: null,
	origin_country_id: '',
	origin_country_name: '',
	date: '',
	artist_id: 1,
	observations: '',
	owner_id: 1,
	owner_status: 'CONSERVADO',
	dimensions_height: null,
	dimensions_length: null,
	dimensions_width: null,
	dimensions_weight: null,
	dimension_base: false,
	dimensions_base_height: null,
	dimensions_base_length: null,
	dimensions_base_width: null,
	dimensions_base_weight: null,
	technique: '',
	holder: '',
	materials: '',
	difference: '',
	location_id: 1,
	created_by_id: 1,
	created_by_name: '',
	components: false,
	components_number: null,
	components_detail: '',
	price: 0,
	location_name: 1,
	location_room_name: '',
	location_general_name: '',
	organization_user_id: 1,
	description: '',
	preservation_status: '',
	name_image: '',
	description_image: '',
};

const filterArtworkGalleryInitialValues = {
	artist_id: '',
	artwork: '',
	date: '',
	materials: '',
};

const filterArtworkArtistInitialValues = {
	artwork: '',
	date: '',
	materials: '',
};

export {
	artworkSingleTabs,
	singleArtworkButtons,
	catalogueArtistArtworkSingleTabs,
	catalogueArtistSingleArtworkButtons,
	artworkValidationSchema,
	artworkFormValidationSchema,
	artworkFormValidationSchemaArtistSimple,
	artworkInitialValues,
	artworkValidationSchemaArtistSimple,
	filterArtworkGalleryInitialValues,
	filterArtworkArtistInitialValues,
	artworkObjectTypes,
	artworkResolutions,
	artworkAspects,
	artworkVideoFormValidationSchema,
	artworkVideoSingleTabs,
	singleArtworkVideoButtons,
	artworkVideoValidationSchema,
	artworkSingleTabsArtist,
	artworkVideoSingleTabsArtist,
};
