//3rd party
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';
import {
	filterPortfolioArtistInitialValues,
	filterPortfolioGalleryInitialValues,
} from '../../../BusinessLogic/data/PortfolioSingle';

//UI
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import ButtonFabPrincipalUrl from '../../../UI/atoms/Buttons/FAB/ButtonFabPrincipalUrl';
import ViewAllSkeleton from '../../../UI/templates/SkeletonTemplates/ViewAllSkeleton';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ImageGridOneToThree from '../../../UI/organisms/GridView/ImageGridOneToThree';
import BlankSpacesTemplate from '../../../UI/templates/BlankSpaces/BlankSpacesTemplate';
import portfoliosImage from '../../../UI/assets/images/BlankSpacesImages/portfoliosImg.jpg';
import FilterAllPortfolios from '../../../UI/molecules/Filters/FilterAllPortfolios';

/**
 * @description manages the Portfolio All page.
 * We can refactor the 2 menus so that they work like one.
 * @returns
 */

const PortfolioAll = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { portfolios, status } = useSelector((state) => state.portfolios);
	const { appUser } = useSelector((state) => state.appUser);

	const isGalleryUser = appUser.role_company === 'GALLERY';

	const filterInitialValues = () => {
		if (isGalleryUser) {
			return filterPortfolioGalleryInitialValues;
		} else {
			return filterPortfolioArtistInitialValues;
		}
	};
	const [valuesFilterPortfolios, setValuesFilterPortfolios] = useState(
		filterInitialValues()
	);
	const [allPortfolios, setAllPortfolios] = useState([]);

	useEffect(() => {
		if (!portfolios) {
			dispatch(getPortfolios());
		} else {
			setAllPortfolios(portfolios);
		}
	}, [portfolios, dispatch]);

	useEffect(() => {
		const handleFilterArtworks = () => {
			if (portfolios) {
				let dataFiltered = portfolios;
				Object.keys(valuesFilterPortfolios)?.forEach((field) => {
					if (valuesFilterPortfolios[field] !== '') {
						if (field === 'portfolio') {
							dataFiltered = dataFiltered.filter((item) =>
								item?.name
									.toLowerCase()
									.includes(valuesFilterPortfolios[field].toLowerCase())
							);
						} else {
							dataFiltered = dataFiltered?.filter((item) => {
								if (typeof item[field] === 'number') {
									return item[field] === Number(valuesFilterPortfolios[field]);
								} else {
									return item[field] === valuesFilterPortfolios[field];
								}
							});
						}
					}
				});
				setAllPortfolios(dataFiltered);
			}
		};
		handleFilterArtworks();
	}, [valuesFilterPortfolios, portfolios]);

	if (status === 'loading') {
		return (
			<ViewAllSkeleton
				numberOfImages={portfolios?.length}
				designSkeletonTitle4={'invisible'}
				designSkeletonImages={
					'w-full px-4 grid grid-cols-3 sm:grid-cols-5 md:grid-cols-7 2xl:grid-cols-9 gap-x-2 gap-y-0 justify-center'
				}
			/>
		);
	}

	return (
		<TemplateWithNavBar section={'Portafolios'}>
			<div className='my-5 mb-10 flex items-center justify-between md:my-10'>
				<div className='invisible w-8 '></div>
				<TextMainTitle
					title={t('Portafolios')}
					textColor='text-yellow-50'
					color={'bg-yellow-50'}
				/>
				<FilterAllPortfolios
					valuesFilterPortfolios={valuesFilterPortfolios}
					setValuesFilterPortfolios={setValuesFilterPortfolios}
					valuesFilterInitialValues={filterInitialValues()}
				/>
			</div>
			{Array.isArray(portfolios) && portfolios.length === 0 && (
				<BlankSpacesTemplate
					title={'HazteConocer'}
					subtitle={'CreaTusPortafolios'}
					text={'CreaYDiseñaTuPortafolio'}
					image={portfoliosImage}
					message={allPortfolios.message}
					link={'/portafolios/plantillas'}
					designImage='max-h-[30vh] md:max-h-[45vh]'
				/>
			)}
			<ImageGridOneToThree
				gridImages={allPortfolios}
				displayPortfolioOptions={true}
				newEntityLink={'/portafolios/plantillas'}
			/>
			<div className='h-48' />

			<ButtonFabPrincipalUrl label={t('Crear')} url='/portafolios/plantillas' />
		</TemplateWithNavBar>
	);
};

export default PortfolioAll;
