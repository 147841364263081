/* 3rd party */
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* BL */
import {
	getArtwork,
	putArtwork,
	getArtworks,
	getArtworkDropdown,
	organizeImageGrid,
} from '../../../BusinessLogic/redux/reducers/artwork';
import {
	singleArtworkButtons,
	artworkValidationSchema,
	artworkValidationSchemaArtistSimple,
	catalogueArtistSingleArtworkButtons,
	singleArtworkVideoButtons,
	artworkVideoValidationSchema,
} from '../../../BusinessLogic/data/ArtworkSingleData';
import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { ENDPOINTS_DOCUMENT } from '../../../BusinessLogic/helpers/routes';
import { createImage } from '../../../BusinessLogic/redux/reducers/createImage';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';
import {
	getCollections,
	resetCollectionSingle,
} from '../../../BusinessLogic/redux/reducers/collection';
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';

/* UI */
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import TabDocumentAndEdit from '../../../UI/organisms/Tabs/TabDocumentAndEdit';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import ImageCarousel from '../../../UI/molecules/Image/ImageCarousel';
import PageNotFound from '../../fallBackPages/PageNotFound';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';
import VideoPlaceholder from '../../../UI/molecules/Video/VideoPlaceholder';
import ReactPlayer from 'react-player';
import { ModalPostulation } from '../../../UI/organisms/PopUp/ModalPostulation';

/**
 * @description renders the single artwork section
 * @param {*} param0
 * @returns
 */
function ArtworkSingle() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const {
		singleArtwork,
		artworkSingleTabs,
		catalogueArtistArtworkSingleTabs,
		artworkDropdown,
		artworks,
		status,
		artworkVideoSingleTabs,
		artworkSingleTabsArtist,
		artworkVideoSingleTabsArtist,
	} = useSelector((state) => state.artworks);
	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const [indexInfo, setIndexInfo] = useState(0);
	const [showModalCannotDelete, setShowModalCannotDelete] = useState(false);
	const { appUser } = useSelector((state) => state.appUser);
	const { t } = useTranslation();

	const rolCompany = appUser.role_company;
	const isCatalogueArtistUser = rolCompany === 'ARTIST_CATALOGATION';
	const isGalleyUser = rolCompany === 'GALLERY';
	const isVideoArtwork =
		singleArtwork?.object_type === 'VIDEO' ||
		singleArtwork?.object_type === 'PERFORMANCE' ||
		singleArtwork?.object_type === 'ANIMATION';

	const singleTabsContent = isCatalogueArtistUser
		? {
				singleTabs: catalogueArtistArtworkSingleTabs,
				singleButtons: catalogueArtistSingleArtworkButtons,
				validationSchema: artworkValidationSchema,
		  }
		: isVideoArtwork
		? {
				singleTabs: isGalleyUser
					? artworkVideoSingleTabs
					: artworkVideoSingleTabsArtist,
				singleButtons: singleArtworkVideoButtons,
				validationSchema: artworkVideoValidationSchema,
		  }
		: {
				singleTabs: isGalleyUser ? artworkSingleTabs : artworkSingleTabsArtist,
				singleButtons: singleArtworkButtons,
				validationSchema: artworkValidationSchemaArtistSimple,
		  };

	const { singleTabs, singleButtons, validationSchema } = singleTabsContent;

	const imagesForCarousel = () => {
		return singleTabs?.find((tab) => tab.type === 'Images')?.content[0]
			?.information;
	};

	useEffect(() => {
		if (Object.keys(artworkDropdown).length === 0) {
			dispatch(getArtworkDropdown()).then(() => {
				dispatch(getArtwork(id));
			});
		} else {
			dispatch(getArtwork(id));
		}
		//eslint-disable-next-line
	}, []);

	function onSubmitAction(values) {
		dispatch(putArtwork(values)).then(() => {
			dispatch(getArtwork(id));
			dispatch(getArtworks());
		});
	}

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'ARTWORK',
				type_id: id,
				organization_user_id: singleArtwork?.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getArtwork(id));
	};

	const deleteDocumentFunction = (documentId) => {
		let values = {
			element_id: singleArtwork?.id,
			element: 'DOCUMENT',
			url: `${ENDPOINTS_DOCUMENT.DELETE_DOCUMENT}${documentId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getArtwork(id)));
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleArtwork?.id,
			element: 'ARTWORK',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getArtwork(id)));
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'ARTWORK',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getArtworks());
			dispatch(getArtwork(id));
		});
	};

	const checkRedirect = () => {
		let redirect = '/obras';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};
	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const textGoBackProfile =
			rolCompany !== 'GALLERY' ? 'RegresarPerfil' : 'RegresarArtistas';
		const defaultMessage = 'RegresarObras';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage,
			textGoBackProfile
		);
		return t(resultMessage);
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};

	const renderSwitch = () => {
		if (Array.isArray(singleTabs) && Array.isArray(validationSchema)) {
			switch (singleTabs[indexInfo]?.type) {
				case 'Information':
					return (
						<TabInfoAndEdit
							information={singleTabs[indexInfo]}
							indexInfo={indexInfo}
							colorHr='yellow'
							onSubmitAction={onSubmitAction}
							id={id}
							validationSchema={validationSchema[indexInfo]}
						/>
					);
				case 'Images':
					return (
						<TabImageAndEdit
							information={singleTabs[indexInfo]}
							indexInfo={indexInfo}
							color='yellow'
							createImagesFunction={createImagesFunction}
							deleteImagesFunction={deleteImagesFunction}
							submitOrganizeImageGrid={submitOrganizeImageGrid}
							objectFitProp='contain'
						/>
					);
				case 'Documents':
					return (
						<TabDocumentAndEdit
							information={singleTabs[indexInfo]}
							indexInfo={indexInfo}
							color='yellow'
							saveLastSectionURL={true}
							AddIconLink={`/documentos/new`}
							saveURLHistory={saveURLHistory}
							deleteDocumentFunction={deleteDocumentFunction}
						/>
					);

				default:
					return <h1>No hay documentos disponible</h1>;
			}
		}
	};

	if (status === 'loading' && !singleArtwork) {
		return (
			<ViewSingleSkeleton
				numberOfTitleAndFields={1}
				numberOfSingleRow={7}
				numberOfSingleTitleAndField={2}
				designCaption={'hidden'}
			/>
		);
	}

	if (singleArtwork?.code === 403 || singleArtwork?.code === 404) {
		return <PageNotFound />;
	}

	if (!singleArtwork) {
		return <PortfolioLoading />;
	}

	return (
		<div>
			{showModalCannotDelete && (
				<ModalPostulation
					modalTitle={t('ObraNoPuedeEliminarse')}
					modalText={t('ObraNoPuedeEliminarseDescription')}
					buttonLabel={t('Cerrar')}
					modalClassName={'mx-auto w-4/5 px-8'}
					actionClose={() => setShowModalCannotDelete(false)}
				/>
			)}
			{singleArtwork && (
				<TemplateWithNavBar section='obras'>
					<NavbarSecondaryNew
						title={singleArtwork?.name}
						color='bg-yellow-50'
						textColor='text-yellow-50'
						textPopup={`${t('EliminarObra')}`}
						popupIcon={<Delete />}
						modalTitle={`${t('EliminarObra')}`}
						modalTextOne={`${t('SeguroQueDeseasEliminarEstaObra')}`}
						modalTextTwo={elementInfo}
						id={id}
						section='obras'
						redirect={checkRedirect()}
						textBack={CheckTextBack()}
						textBackDesign='pl-3 text-yellow-50'
						backAction={() => returnBackAction()}
						arrowLeftColor='yellow'
						onDeleteAction={() => {
							dispatch(getCollections());
							dispatch(resetCollectionSingle());
							dispatch(getPortfolios());
						}}
						setShowModalCannotDelete={setShowModalCannotDelete}
					/>
					<ButtonFabExtendeNav
						id={id}
						listItems={artworks?.length ? artworks : []}
						section='obras'
					/>
					<div className='flex w-full flex-col gap-8 md:flex-row'>
						<div className='flex w-full flex-col justify-start md:w-1/3 '>
							{isVideoArtwork ? (
								<div className='aspect-square w-full overflow-hidden p-2'>
									{singleArtwork?.video_url ? (
										<ReactPlayer
											url={singleArtwork?.video_url}
											width={'100%'}
											height={'100%'}
											controls
										/>
									) : (
										<VideoPlaceholder text={t('ArchivosPermitidosVideo')} />
									)}
								</div>
							) : (
								<ImageCarousel
									images={imagesForCarousel()}
									mainPicture={singleArtwork?.main_picture_url}
									isThereACoverImageButton={false}
									containerDesign='w-full p-2'
									sectionTitle={'Images'}
								/>
							)}
						</div>

						<div className='flex w-full flex-col md:w-2/3'>
							<NewTagButtons
								buttonList={singleButtons}
								indexInfo={indexInfo}
								setIndexInfo={setIndexInfo}
							/>

							<div className='min-h-screen bg-light_grey-50 py-4'>
								{renderSwitch()}
							</div>
						</div>
					</div>
				</TemplateWithNavBar>
			)}
		</div>
	);
}

ArtworkSingle.propTypes = {};

export default ArtworkSingle;
