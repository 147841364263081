//3rd party components
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//BL
import { changeCollectionState } from '../../../BusinessLogic/redux/reducers/collection';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import BlankImage from '../../assets/images/BlankImage.png';
import { ReactComponent as RectanglePlantilla } from '../../assets/icons/RectanglePlantilla.svg';
import { setUniqueIdChosen } from '../../../BusinessLogic/redux/reducers/portfolio';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';

/**
 * @description Molecule that has the image information.
 * @changes Has a dispatch (BL) in a molecule. We should remove
 * @param {*} param0
 * @returns
 */

export default function ImageAndTitlePlantilla({
	id,
	imagen,
	title,
	redirect,
	imageFormat,
}) {
	const { t } = useTranslation();
	const { uniqueIdChosen } = useSelector((state) => state.portfolios);
	const dispatch = useDispatch();

	let image_format = '';
	if (imageFormat === 'small') {
		image_format = '_medium';
	}

	let defaultImage = BlankImage;

	return (
		<div onClick={() => dispatch(changeCollectionState(true))}>
			<div className={'flex w-full flex-col items-start'}>
				<div className='relative flex h-40 w-40 items-center justify-center bg-light_grey-75'>
					<LazyLoadImage
						key={id}
						alt={title}
						src={imagen ? imagen + image_format : defaultImage}
						width=''
						height=''
						effect='blur'
						wrapperClassName='h-36 overflow-hidden w-full flex justify-center items-center lazyLoadingImages'
						delayTime='0'
						treshold='800'
						style={{ opacity: 0.5 }}
					/>
					<Link
						to={{ pathname: '/plantilla/portafolio_basico' }}
						className='absolute  h-8 w-3/4'
					>
						<button className='w-full rounded-lg bg-grey-50 text-center text-white'>
							{t('Visualizar')}
						</button>
					</Link>
				</div>
				<div className='mt-3 flex h-12 w-11/12 flex-row items-center'>
					{redirect === 'plantilla' && (
						<RectanglePlantilla
							className='mr-2 cursor-pointer'
							key={id}
							onClick={() => {
								uniqueIdChosen === id
									? dispatch(setUniqueIdChosen(null))
									: dispatch(setUniqueIdChosen(id));
							}}
							fill={uniqueIdChosen === id ? '#9CBDB9' : 'none'}
						/>
					)}
					<div
						onClick={() => {
							uniqueIdChosen === id
								? dispatch(setUniqueIdChosen(null))
								: dispatch(setUniqueIdChosen(id));
						}}
					>
						<TextContentPrincipal design='lg:cursor-pointer' text={t(title)} />
					</div>
				</div>
			</div>
		</div>
	);
}

ImageAndTitlePlantilla.propTypes = {
	/** Id of the image. This id is used for navigation */
	id: PropTypes.string,
	/** URL link to the image */
	imagen: PropTypes.string,
	/** Title on the card */
	title: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/** Manages the format of the document */
	imageFormat: PropTypes.string,
	/** Checks if the element is public*/
	isPublic: PropTypes.string,
};

ImageAndTitlePlantilla.defaultProps = {
	imageFormat: 'small',
};
