import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
//BL
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';

//UI
import DocumentTypes from '../../../Pages/sections/document/DocumentTypes';
import ButtonFab from '../../atoms/Buttons/FAB/ButtonFab';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import FilesSection from '../../organisms/Album/FilesSection';
import NavbarSecondaryNew from '../../organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../TemplateWithNavBar';
import ViewModal from '../ViewModal';
import { ReactComponent as Cross } from '../../assets/icons/Cross.svg';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { ReactComponent as PortfolioWhite } from '../../assets/icons/PortfolioWhite.svg';

const CatalogueSelection = ({
	setCatalogueView,
	setPreselectedArtworkIds,
	preselectedArtworkIds,
	postulationSingle,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { artworks } = useSelector((state) => state.artworks);
	const { appUser } = useSelector((state) => state.appUser);
	const isGalleryUser = appUser.role_company === 'GALLERY';

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [currentArtworks, setCurrentArtworks] = useState([]);

	useEffect(() => {
		if (!artworks) {
			dispatch(getArtworks());
		}
		if (artworks)
			setCurrentArtworks(
				isGalleryUser
					? artworks.filter(
							({ artist_id }) => postulationSingle.artist_id === artist_id
					  )
					: artworks
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artworks, dispatch]);

	const preselectedArtworks =
		Array.isArray(currentArtworks) &&
		currentArtworks?.filter((artwork) =>
			preselectedArtworkIds.includes(artwork.id)
		);

	const toggleSelectedArtwork = (artwork) => {
		const artworkId = artwork.id;

		if (preselectedArtworkIds.includes(artworkId)) {
			setPreselectedArtworkIds((preselected) =>
				preselected.filter((preselectedId) => preselectedId !== artworkId)
			);
		} else {
			setPreselectedArtworkIds((preselected) => [...preselected, artworkId]);
		}
	};

	const cleanAllSelectedArtworks = () => {
		setPreselectedArtworkIds([]);
	};

	const handleSelectAllArtworks = (e) => {
		setPreselectedArtworkIds([]);
		if (e.target.checked) {
			currentArtworks.forEach((artwork) =>
				setPreselectedArtworkIds((preselected) => [...preselected, artwork.id])
			);
		}
	};

	const saveSelectedArtworks = () => {
		if (preselectedArtworkIds.length > 0) setCatalogueView('confirmationView');
	};

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={t('Catalogo')}
				textColor='text-purple-50'
				color='bg-purple-50'
				showDots={false}
				redirect={`/postulaciones/${postulationSingle?.id}`}
				textBack={`${t('RegresarAPostulación')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
			/>

			<div className='mx-3 flex w-full gap-4 py-8'>
				<div className='flex h-14 w-14 items-center justify-center rounded-full bg-green-50 p-4'>
					<PortfolioWhite />
				</div>
				<div className='flex w-full flex-col gap-2 px-4'>
					<TextTitleSub
						title={t('CatalogoDeObras')}
						design={'text-purple-50'}
					/>
					<TextContentPrincipal
						text={t('ParaAplicarAEstaConvocatoriaDebesSeleccionar')}
					/>
				</div>
			</div>

			<div className='mx-3 flex w-full flex-col py-4'>
				<TextTitleSub
					title={t('ObrasDisponiblesEnDatarte')}
					textColor='text-purple-50'
				/>
				<div className='mr-6'>
					<UnderlineHr design={'mr-6'} colour={'purple'} />
				</div>
				<TextContentPrincipal
					design={`${currentArtworks.length === 0 && 'text-red-500'} py-4`}
					text={
						Array.isArray(currentArtworks) & (currentArtworks.length > 0)
							? t('SeleccionaLasObrasQueQuieres')
							: 'Para aplicar por favor agrega obras a Datarte para luego poder adjuntarlas a la convocatoria.'
					}
				/>
				{(!Array.isArray(currentArtworks) || currentArtworks.length === 0) && (
					<div className='flex w-full justify-center py-10'>
						<Link to={'/obras/new'}>
							<ButtonFab
								size='large'
								label={'Crea una obra'}
								colour='white'
								design={'shadow-md w-max'}
							/>
						</Link>
					</div>
				)}
			</div>

			{Array.isArray(currentArtworks) && currentArtworks.length !== 0 && (
				<div className='mx-3 md:mb-20 md:h-full'>
					<div className='flex w-full flex-col items-center justify-between gap-4 pt-2 pb-4 sm:flex-row'>
						<div className='flex w-full flex-col items-center justify-between gap-4 pt-2 pb-4 md:w-1/2'>
							<div className='flex w-full flex-row items-center justify-between gap-4'>
								<TextContentPrincipal
									design={'text-grey-50'}
									text={`${preselectedArtworks.length} ${t(
										'obrasSeleccionadas'
									)}`}
									style={{ fontWeight: 400 }}
								/>
								<div className='flex flex-row gap-4'>
									<Cross
										className='cross-svg h-6 w-6 cursor-pointer'
										onClick={() => {
											if (preselectedArtworkIds.length > 0)
												setShowDeleteModal(true);
										}}
									/>
									<Check
										className='check-svg h-6 w-6 cursor-pointer'
										onClick={() => {
											if (preselectedArtworkIds.length > 0)
												saveSelectedArtworks();
										}}
									/>
								</div>
							</div>
							<label className='flex w-full flex-row items-center justify-between gap-4'>
								<TextContentPrincipal
									design={'text-grey-50'}
									text={t('SeleccionarTodasLasObras')}
									style={{ fontWeight: 400 }}
								/>
								<input
									type='checkbox'
									checked={
										currentArtworks.length === preselectedArtworkIds.length
									}
									onChange={handleSelectAllArtworks}
								/>
							</label>
						</div>
						<div className='hidden w-full justify-center py-6 md:block'>
							<h3 className='flex w-full justify-end p-2 font-normal'>
								{`${currentArtworks.length - preselectedArtworks.length} ${t(
									'ObrasMasDisponibles'
								)}`}
							</h3>
						</div>
					</div>

					<div className='flex h-full flex-col justify-end gap-4 md:flex-row'>
						{preselectedArtworks.length > 0 && (
							<div className='flex h-40 max-h-full w-full overflow-y-hidden overflow-x-scroll bg-light_grey-75 md:h-screen md:w-1/4 md:overflow-x-hidden md:overflow-y-scroll'>
								<div className='mx-auto flex max-h-full flex-row flex-nowrap gap-5 p-4 md:h-full md:w-3/5 md:flex-col'>
									{preselectedArtworks.map((file, index) => (
										<div key={index} style={{ aspectRatio: '1/1' }}>
											<DocumentTypes
												document={file}
												preSelectedFiles={preselectedArtworks}
												onClick={() => toggleSelectedArtwork(file)}
											/>
										</div>
									))}
								</div>
							</div>
						)}
						<div className='h-96 w-full border md:h-screen'>
							<div className='flex max-h-full w-full flex-col gap-4 overflow-x-scroll overflow-y-scroll  md:h-full md:overflow-x-hidden'>
								<FilesSection
									files={currentArtworks}
									text={'obras'}
									action={toggleSelectedArtwork}
									preSelectedFiles={preselectedArtworks}
								/>
							</div>
						</div>
					</div>

					<h3 className='flex w-full justify-center p-2 py-6 font-normal md:hidden'>
						{`${currentArtworks.length - preselectedArtworks.length} ${t(
							'ObrasMasDisponibles'
						)}`}
					</h3>
				</div>
			)}
			{currentArtworks.length === 0 && (
				<div className='flex w-full justify-center py-8'>
					<ButtonFab
						colour={'white'}
						label={'Agregar obra'}
						size={'large'}
						design={'shadow-md'}
					/>
				</div>
			)}
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={cleanAllSelectedArtworks}
					closeMenu={setShowDeleteModal}
					modalTitle={t('EliminarSelección')}
					modalTextOne={t('SeguroQueDeseasEliminarLasObrasSeleccionadas')}
				/>
			)}
		</TemplateWithNavBar>
	);
};

export default CatalogueSelection;
